import axios from "axios";
import React, { useEffect, useState } from "react";
import { LeaderUser, User_feeds  } from "src/interface";
// import { useParams} from "react-router-dom";
import { useAppSelector } from "src/hooks/redux";
import moment from "moment";
import "moment/locale/ru";
moment.locale("ru");
import { authHeader } from "src/utils/authHeader";

export const TeacherUsers = () => {
  document.title = "Курсы | EasyENT";
  // const params = useParams();
  const [userFeeds, setUserFeeds] = useState<User_feeds[]>([]);
  const [users, setUsers] = useState<LeaderUser[]>([]);
  const user = useAppSelector((store) => store.user.data);
  useEffect(() => {
    if(!user.id){
      console.log('пустo')
    }
    else{
      setUserFeeds(user.user_feeds);
    }
  }, []);
  useEffect(() => {
    if(userFeeds.length > 0){
      axios
      .get("/api/v1/courses/" + userFeeds[0]['course_id'] + "/leader-board?in_feed_date=2023-11-11",{ headers: authHeader()})
      .then((response) => setUsers(response.data));
      }
  }, [userFeeds]);  
  return (
    <div>
      <h1 className="title">Ученики</h1>
      <div className="select-part">
        <label className="select-part__heading">Сортировать:</label>
        <select>
          <option>Лучшие ученики</option>
          <option>Худшие ученики</option>
          <option>По новинке</option>
        </select>
      </div>
      <table className="table">
        <tbody>
        <tr>
          <th style={{width: '40px'}}>
            Id
          </th>
          <th style={{width: '220px'}}>
            Фио
          </th>          
          <th style={{width: '140px'}}>
            Номер телефона
          </th>
          <th style={{width: '90px'}}>
            Статус
          </th>
          <th style={{width: '90px'}}>
            Коины
          </th>
          <th style={{width: '130px'}}>
            Успеваемость
          </th>
        </tr>
        {users.map((user) => (
          <tr key={user.user_id}>
            <td style={{width: '40px'}}>
              {user.user_id}
            </td>
            <td style={{width: '220px'}}>
              {user.full_name}
            </td>
            <td style={{width: '140px'}}>
              {user.phone}
            </td>
            <td style={{width: '140px'}}>
              {user.status_student !== true ? "отказ" : ""}
            </td>
            <td style={{width: '90px'}}>
              {user.coins.toFixed(2)}
            </td>
            <td style={{width: '130px'}}>
              <div className="item-control">
                
              </div>
            </td>
          </tr> 
        ))}
        </tbody>
      </table>
    </div>
  );
};
