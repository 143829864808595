import React from "react";
import { NavLink, Link } from "react-router-dom";

// import "./Navbar_teacher.scss";

import { ReactComponent as CalendarLTRIcon } from "../../svg/calendar-ltr.svg";
import { ReactComponent as PeopleIcon } from "../../svg/people.svg";
import { ReactComponent as PersonIcon } from "../../svg/person.svg";
import { ReactComponent as Power } from "../../svg/power.svg";

import { useAppDispatch } from "src/hooks/redux";
import { useNavigate } from "react-router-dom";
import { UserSlice } from "src/service/reducers/UserSlice";

export const Navbar_teacher = () => {
  const { signOutUser } = UserSlice.actions;
  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  const users = {
    fullName: "",
    phone: "",
    postalCode: "",
    isStudent: true,
    personData: false,
    mailAdvertising: false,
    publicOffers: false,
  };

  const signOut = function () {
    dispatch(signOutUser(users));
    navigate("/login");
  };

  return (
    <div className="navbar">
      <Link to="/teacher/calendar" className="logo">
        <img src="/assets/svg/EasyEnt.svg" />
      </Link>
      <nav>
        <NavLink
          className={({ isActive }) => (isActive ? "active" : undefined)}
          to="/teacher/calendar"
        >
          <CalendarLTRIcon />
          Мои курсы
        </NavLink>
        <NavLink
          className={({ isActive }) => (isActive ? "active" : undefined)}
          to="/teacher/users"
        >
          <PeopleIcon />
          Ученики
        </NavLink>
        <NavLink
          className={({ isActive }) => (isActive ? "active" : undefined)}
          to="/teacher/tests"
        >
          <PersonIcon />
          Тесты ЕНТ
        </NavLink>
        <NavLink
          className={({ isActive }) => (isActive ? "active" : undefined)}
          to="/teacher/profile"
        >
          <PersonIcon />
          Мой профиль
        </NavLink>
        <span className="logout" onClick={signOut.bind(this)}>
          <Power />
          Выход
        </span>
      </nav>
    </div>
  );
};
