import React from "react";
import "./Popconfirm.scss";

type TProps = {
  visible: boolean;
  header: string;
  body?: React.ReactNode;
  footer?: React.ReactNode;
};

export function Popconfirm({ visible, header, body, footer }: TProps) {
  return (
    <div className={visible ? "modal active" : "modal"}>
      <div className="modal__content">
        <div className="modal__header">{header}</div>
        {body}
        {footer}
      </div>
    </div>
  );
}
