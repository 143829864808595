import React, { FC} from "react";
import { Link } from "react-router-dom";
import Skeleton from "react-loading-skeleton";

import { ICourse} from "src/interface";
import { Penalty } from "src/components";
import "./MyCourseItem.scss";
import { useTranslation } from "react-i18next";
import { namespaces } from "src/i18n/i18n.constants";

export const MyCourseItem: FC<{ course: ICourse; showLessons?: boolean }> = ({
  course,
  showLessons,
}) => {
  if (showLessons) {
    document.title = `${course.name} | EasyENT`;
  }
  const { t } = useTranslation(namespaces.pages.hello);
  // const [setProgress] = useState<number>(0);
  const subjects:any = [
    {id:1, title: "История Казахстана"},
    {id:5, title: "Математическая Грамотность"},
    {id:6, title: "Грамотность Чтения"},
    {id:10, title: "Математика"},
    {id:121, title: "Информатика"},
    {id:110, title: "Английский язык"},
    {id:100, title: "Всемирная история"},
    {id:91, title: "География"},
    {id:48, title: "Биология"},
    {id:29, title: "Химия"},
    {id:23, title: "Физика"},
    {id:232, title: "Қазақстан тарихы"},
    {id:248, title: "Оқу сауаттылығы"},
    {id:265, title: "Математикалық сауаттылық"},
    {id:317, title: "Математика"},
    {id:336, title: "Химия"},
    {id:356, title: "Физика"},
    {id:377, title: "Биология"},
    {id:399, title: "География"},
    {id:422, title: "Дүниежүзі тарихы"},
    {id:446, title: "Ағылшын тілі"},
    {id:471, title: "Информатика"},
  ]
  const obj = subjects.find((o:any) => o.id === course.subject_id);
  const whatButtonToShow =
    showLessons === true ? (
      <a
        target="_blank"
        rel="noreferrer"
        href={course.tg_chat_id}
        className="btn btn-primary btn-telegram btn-flex"
      >
        {t("inner_course.Телеграм_чат_с_кураторами", { ns: namespaces.common })} 
        <img src="assets/svg/telegram.svg" />
      </a>
    ) : (
      <Link to={`/course_${course.id}`} className="btn btn-primary btn-flex">
        {t("general.Подробнее_о_курсе", { ns: namespaces.common })}<i className="fa-solid fa-angle-right"></i>
      </Link>
    );

  // function getProgress() {
  //   axios
  //     .get(`/api/v1/courses/${course?.id}/myprogress`, { headers: authHeader() })
  //     .then((res: any) => {
  //       setProgress(res?.data?.progress);
  //     });
  // }

  // useEffect(() => {
  //   getProgress();
  // }, []);

  return (
    <>
      <div className="course-item box">
        <Link to={`/course_${course.id}`} className="image-wrapper">
          <img src={course.photo_url} />
        </Link>
        <div className="course-wrapper">
          <span className="course-wrapper__title">{course.name}</span>
          <div className="course-details">
            <div>
              <span>
                <i className="fa-solid fa-book"></i>
              </span>
              {
                obj !== undefined ? obj.title : "" 
              }
            </div>
            <div>
              <span>
                <i className="fa-solid fa-user"></i>
              </span>
              {course.teacher.name}
            </div>
            <div className="course-penalty course-penalty--mini">
              {<Penalty penalties={course.penalties}/>}
            </div>
          </div>
          {/* <ProgressBar completed={progress} /> */}
          {whatButtonToShow}
        </div>
        
      </div>
    </>
  );
};

export const CourseItemSkeleton = () => {
  return (
    <div className="course-item">
      <div className="image-wrapper">
        <Skeleton width={300} height={200} />
        <div className="play-btn">
          <i className="fa-solid fa-play"></i>
        </div>
      </div>
      <div className="course-wrapper">
        <h2>
          <Skeleton width={338} height={32} />
        </h2>
        <p>
          <Skeleton width={200} height={32} />
        </p>
        <div className="course-details">
          <Skeleton width={170} height={17} />
          <Skeleton width={150} height={17} />
          <Skeleton width={170} height={17} />
          <Skeleton width={150} height={17} />
        </div>
        <Skeleton width={200} height={50} />
      </div>
    </div>
  );
};
