/* eslint-disable no-unsafe-optional-chaining */
import React, { useEffect, useState } from "react";
import { useNavigate, Link } from "react-router-dom";
import Select from "react-select";
import toast from "src/utils/toast";
import axios from "src/utils/axios";
import moment from "moment";

import { authHeader } from "src/utils/authHeader";
import { Preloader } from "src/components";
import "./TestsPage.scss";

interface IOption {
  value: string;
  label: string;
  id?: number;
  lang?: string;
  type?: string;
  parents?: IOption[] | any;
  children?: IOption[] | any;
}

const selectStyles = {
  control: (styles: any) => ({
    ...styles,
    height: "60px",
    background: styles.isDisabled ? "hsl(228deg 6% 85%)" : styles.background,
  }),
};

const testTypes: IOption[] = [
  { value: "ONE", label: "Тест по предмету" },
  { value: "FULL", label: "Полный тест" },
];

export const TestsPage = () => {
  document.title = "Тесты | EasyENT";
  const navigate = useNavigate();
  const [activeProcess, setActiveProcess] = useState<any>();
  const [allProcess, setAllProcess] = useState<Array<any>>([]);
  const [loadingActive, setActiveLoading] = useState(false);
  const [loadingAll, setAllLoading] = useState(false);
  const [testType, ] = useState<IOption | null>(testTypes[0]);
  const [subjects, setSubjects] = useState<Array<IOption>>([]);
  const [oneSubject, setOneSubject] = useState<IOption | null>(null);
  const [tests, setTests] = useState<any>(null);
  const [language, ] = useState<any>(localStorage.getItem('language'));
  const [selectedTest, setSelectedTest] = useState<any>(null);
  const [profileSubjects1, setProfileSubjects1] = useState<Array<IOption>>([]);
  const [profileSubject1, setProfileSubject1] = useState<IOption | null>(null);
  const [profileSubjects2, setProfileSubjects2] = useState<Array<IOption>>([]);
  const [profileSubject2, setProfileSubject2] = useState<IOption | null>(null);

  function getActiveProcess() {
    setActiveLoading(true);
    axios
      .get("/api/v1/tests/process/active", { headers: authHeader() })
      .then((res: any) => {
        setActiveProcess(res.data);
      })
      .finally(() => setActiveLoading(false));
  }

  function getAllProcess() {
    setAllLoading(true);
    axios
      .get("/api/v1/tests/process/?skip=0&limit=1000", {
        headers: authHeader(),
      })
      .then((res: any) => {
        setAllProcess(res.data);
      })
      .finally(() => setAllLoading(false));
  }

  function getSubjects() {
    axios
      .get("/api/v1/subject/?skip=0&limit=100", { headers: authHeader() })
      .then((response) => setSubjects(response.data));
     
  }

  function startTest() {
    let test_id;
    if(tests.length > 1){
      test_id = selectedTest.id;
    }
    else{
      test_id = tests[0].id;
    }
    axios
      .post(
        "/api/v1/tests/process/",
        {
          is_full: !!(testType?.value === "FULL"),
          tests: [
              {
                "id": test_id
              }
            ]  
        },
        { headers: authHeader() }
      )
      .then(() => {
        navigate("/tests/ent");
      });
  }

  useEffect(() => {
    if (testType?.value === "ONE") {
      setProfileSubject1(null);
      setProfileSubject2(null);
    } else if (testType?.value === "FULL") {
      setProfileSubjects1(
        subjects?.filter(
          (item: IOption) =>
            item?.parents?.length > 0 || item?.children?.length > 0
        )
      );
      setOneSubject(null);
    }
  }, [testType]);

  useEffect(() => {
    getActiveProcess();
    getAllProcess();
    getSubjects();
  }, []);


  let filtertest = [];
  useEffect(() => {
    setAllLoading(true);
    axios
      .get("/api/v1/tests/tests/?limit=50", {
        headers: authHeader(),
      })
      .then((res: any) => {
        filtertest = res.data.reduce(function(filtered:any, option:any) {
          if (option.subject_id == oneSubject?.id) {
             const write_date = "" + option.write_date.slice(0,10); 
             const someNewValue = { id: option.id, label: write_date}
             filtered.push(someNewValue);
          }
          return filtered;
        }, []);
        setTests(filtertest)
      })
      .finally(() => setAllLoading(false));
  }, [oneSubject]);


  useEffect(() => {
    if (profileSubject1?.value) {
      const firstProfileSubject = subjects?.find(
        (item: IOption) => item?.id === profileSubject1?.id
      );
      setProfileSubjects2([
        ...firstProfileSubject?.children,
        ...firstProfileSubject?.parents,
      ]);
      setProfileSubject2(null);
    }
  }, [profileSubject1]);
  
  const staticSubjects = [
    {
        "title": "История Казахстана",
        "type": "primary",
        "id": 1,
        "label": "История Казахстана",
        "value": "1",
        "children": [],
        "parents": []
    },
    {
        "title": "Мат.Грамотность",
        "type": "primary",
        "id": 5,
        "label": "Мат.Грамотность",
        "value": "5",
        "children": [],
        "parents": []
    },
    {
        "title": "Математика",
        "type": "standart",
        "id": 10,
        "label": "Математика",
        "value": "10",
    },
    {
        "title": "Физика",
        "type": "standart",
        "id": 23,
        "label": "Физика",
        "value": "23",
        "children": [],
        "parents": []
    },
    {
        "title": "Химия",
        "type": "standart",
        "id": 29,
        "label": "Химия",
        "value": "29",
        "children": [],
        "parents": []
    },
    {
        "title": "Биология",
        "type": "standart",
        "id": 48,
        "label": "Биология",
        "value": "48",
        "children": [],
        "parents": []
    },
    {
        "title": "География",
        "type": "standart",
        "id": 91,
        "label": "География",
        "value": "91",
    },
    {
        "title": "Английский язык",
        "type": "standart",
        "id": 110,
        "label": "Английский язык",
        "value": "110",
         "children": [],
        "parents": []
    },
    {
      "title": "Всемирная история",
      "type": "standart",
      "id": 100,
      "label": "Всемирная история",
      "value": "100",
   },
    {
      "title": "Информатика",
      "type": "standart",
      "id": 121,
      "label": "Информатика",
      "value": "121",
      "children": [],
        "parents": []
    }
  ]

  const staticSubjectsKZ = [
    {
        "title": "Қазақстан тарихы",
        "type": "primary",
        "lang": "kz_KZ",
        "id": 232,
        "label": "Қазақстан тарихы",
        "value": "232",
        "children": [],
        "parents": []
    },
    {
        "title": "Математикалық сауаттылық",
        "type": "primary",
        "lang": "kz_KZ",
        "id": 265,
        "label": "Математикалық сауаттылық",
        "value": "265",
        "children": [],
        "parents": []
    },
    {
      "title": "Математика",
      "type": "standart",
      "lang": "kz_KZ",
      "id": 317,
      "label": "Математика",
      "value": "317",
      "children": [],
      "parents": []
    },
    {
      "title": "Химия",
      "type": "standart",
      "lang": "kz_KZ",
      "id": 336,
      "label": "Химия",
      "value": "336",
      "children": [],
      "parents": []
    },
    {
      "title": "Биология",
      "type": "standart",
      "lang": "kz_KZ",
      "id": 377,
      "label": "Мат.Грамотность",
      "value": "377",
      "children": [],
      "parents": []
    },
    {
      "title": "География",
      "type": "standart",
      "lang": "kz_KZ",
      "id": 399,
      "label": "География",
      "value": "399",
      "children": [],
      "parents": []
    },
    {
      "title": "Дүниежүзі тарихы",
      "type": "standart",
      "lang": "kz_KZ",
      "id": 422,
      "label": "Дүниежүзі тарихы",
      "value": "422",
      "children": [],
      "parents": []
    },
  ]
  return (
    <div className="tests-page">
      <h1 className="title">Пробные тесты по ЕНТ</h1>
      <div className="flex" style={{ marginBottom: 36 }}>
        {loadingActive ? (
          <Preloader />
        ) : activeProcess?.id ? (
          <>
            <button
              type="button"
              className="btn btn-primary"
              onClick={() => {
                navigate("/tests/ent");
              }}
            >
              Открыть активный тест
            </button>
          </>
        ) : (
          <>
            {/* <Select
              defaultValue={testTypes[0]}
              value={testType}
              options={testTypes}
              onChange={setTestType}
              className="select"
              styles={selectStyles}
            /> */}
            {testType?.value === "ONE" ? (
              <>
                <Select
                  value={oneSubject}
                  options={language ? JSON.parse(language) == "ru" ? staticSubjects : staticSubjectsKZ : staticSubjects}
                  onChange={setOneSubject}
                  placeholder="Предмет"
                  className="select"
                  styles={selectStyles}
                />
                {oneSubject?.value && tests.length > 1 ? (
                  <>
                    <Select
                      value={selectedTest}
                      options={tests}
                      onChange={setSelectedTest}
                      placeholder="Тест"
                      className="select"
                      styles={selectStyles}
                    />
                  </>
                ) : (<div></div>)} 
              </>
            ) : (
              <>
                <Select
                  value={profileSubject1}
                  options={profileSubjects1}
                  onChange={setProfileSubject1}
                  placeholder="Профильный предмет 1"
                  className="select"
                  styles={selectStyles}
                />
                <Select
                  value={profileSubject2}
                  options={profileSubjects2}
                  onChange={setProfileSubject2}
                  placeholder="Профильный предмет 2"
                  className="select"
                  styles={selectStyles}
                  isDisabled={!profileSubject1}
                />
              </>
            )}
            <button
              type="button"
              className="btn btn-primary"
              onClick={() => {
                if (
                  (testType?.value === "ONE" && oneSubject || tests.length > 1 && selectedTest) ||
                  (testType?.value === "FULL" &&
                    profileSubject1 &&
                    profileSubject2)
                ) {
                  return startTest();
                }
                toast("Выберите предмет и тест", "warning");
              }}
            >
              Начать тестирование
            </button>
          </>
        )}
      </div>
      <h1 className="title">Мои тесты</h1>
      {loadingAll ? (
        <Preloader />
      ) : (
        <div>
        <div className="mytest">
          <div className="mytest__top">
            <div className="mytest__id">Id</div>
            <div className="mytest__subject">Предметы</div>
            <div className="mytest__date">Дата завершения</div>
            <div className="mytest__status">Статус</div>
            <div className="mytest__result">Результат</div>
          </div>
          {allProcess &&
            allProcess?.map((process) => (
              <div className="mytest__row" key={process.id}>
                <div className="mytest__id">{process.id}</div>
                <div className="mytest__subject">
                  {process.tests?.length
                    ? process.tests
                        ?.map((test: any) => test?.subject?.title)
                        .join(", ")
                    : "–"}
                </div>
                <div className="mytest__date">
                  {process.end_time
                    ? moment
                        .utc(process.end_time)
                        .local()
                        .format("DD.MM.YYYY HH:mm")
                    : "–"}
                </div>
                <div className="mytest__status">{process.end_time ? "Завершен" : "Активный"}</div>
                <div className="mytest__result">
                  {process.result && process.tests?.length === 1 ? (
                    <Link to="/tests/ent" state={{ processId: process?.id }}>
                      {process.tests
                        ?.map(
                          (test: any) =>
                            `${test.subject.title}: ${
                              process.result?.[test.subject_id]
                            }`
                        )
                        ?.join(", ")}
                    </Link>
                  ) : process.result && process.tests?.length > 1 ? (
                    <Link to="/tests/ent" state={{ processId: process?.id }}>
                      {process.tests
                        ?.map(
                          (test: any) =>
                            `${test.subject.title}: ${
                              process.result?.[test.subject_id]
                            }`
                        )
                        ?.join(", ")
                        .concat(
                          `, Общий балл: ${Object.values(
                            process.result
                          )?.reduce((acc: number, cur: any) => acc + cur, 0)}`
                        )}
                    </Link>
                  ) : (
                    "–"
                  )}
                </div>
              </div>
          ))}
          {/* <div className="mytest__row">
            <div className="mytest__id">Id</div>
            <div className="mytest__subject">Предметы</div>
            <div className="mytest__date">Дата завершения</div>
            <div className="mytest__status">Статус</div>
            <div className="mytest__result">Результат</div>
          </div> */}
        </div>
        </div>
      )}
    </div>
  );
};
