export function authHeader(): any {
    // return authorization header with jwt token
    if (localStorage.getItem('user')) {
        const localStorageItemUser = localStorage.getItem('user')
        const user = localStorageItemUser ? JSON.parse(localStorageItemUser) : null;
        if (user && user.access_token) {
            return { 'Authorization': 'Bearer ' + user.access_token }
        } else {
            return {};
        }
    } else {
        return {};
    }
}

export function getJWT(): any {
    if (localStorage.getItem('user')) {
        const localStorageItemUser = localStorage.getItem('user')
        const user = localStorageItemUser ? JSON.parse(localStorageItemUser) : null;
        if (user && user.access_token) {
            return { 'access_token': user.access_token, 'token_type': user.token_type }
        } else {
            return {};
        }
    } else {
        return {};
    }
}