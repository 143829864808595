import React from "react";
import { Outlet, Navigate } from "react-router-dom";
import { Footer, Header_admin, Navbar_admin } from "../components";

interface IMyProps {
  user:{
    is_teacher: boolean;
    is_superuser: boolean;
  }  
}

export const AdminLayout = ({user}:IMyProps) => {
  if(user.is_superuser == true){
    return (
      <>
        <Header_admin />
        <Navbar_admin />
        <div className="content">
          <div className="content__inner">
            <Outlet />  
          </div>
        </div>
        <Footer />
      </>  
    );  
  }
  else if(user.is_teacher == true){
    return <Navigate to={"/teacher"} replace />; 
  }
  else{
    return <Navigate to={"/"} replace />; 
  }
};

export default AdminLayout;