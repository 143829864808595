// import axios from "axios";
import React, { useState } from "react";
// import { AdminUser,ICourse, StudentStatus, SalesOption, PriceListOption, courseTypeOption, update_user } from "src/interface";
// import { useParams, Link } from "react-router-dom";
// import { authHeader } from "src/utils/authHeader";
// import { Tabs } from 'react-simple-tabs-component';
// import "./AdminUserPerfomance.scss";
// import Select from 'react-select';
// import { CourseListAdmin } from "src/components";

export const AdminUserPerfomance = () => {
  document.title = "Успеваемость | EasyENT";
  // const params = useParams();
  const [user, ] = useState<string>("123");
  return (
    <div>
      <h1 className="title">Успеваемость, Бухарбай Ерсултан</h1>   
      <ul className="title-notes">
        <li>Общая успеваемость: 400</li>
      </ul>
      {user ? (
          <div className="box admin-user-top">
             
            
          </div> ) :
        (
          <p>Загрузка</p>
        )
      }
    </div>
  );
};
