import axios from "axios";
import React, { useEffect } from "react";
import { useParams, useNavigate } from "react-router-dom";
import { getUser } from "../../service/asyncThunk";
import { UserSlice } from "../../service/reducers/UserSlice";
import { Login, Header, Footer } from "src/components";
import { useAppSelector, useAppDispatch } from "src/hooks/redux";

import "./AuthPage.scss";

export const AuthPage = () => {
  document.title = "Войти | EasyENT";
  const params = useParams();
  const dispatch = useAppDispatch();
  const { updateUser, setToken } = UserSlice.actions;

  const navigate = useNavigate();

  const isLoggedIn = useAppSelector((store) => store.user.loggedIn);

  let provider = "";
  if (params.provider) {
    provider = params.provider;
  }

  useEffect(() => {
    if (isLoggedIn) {
      navigate("/calendar");
    }else{
      axios.get(`/api/v1/auth/${provider}${window.location.search}`).then(res=>{
        localStorage.setItem("user", JSON.stringify(res.data));
        dispatch(setToken(res));
        dispatch(getUser()).then((sres: any) => {
          dispatch(updateUser(sres.payload));
          if (sres.payload.is_superuser) {
            navigate("/admin/");
          } else if (sres.payload.is_teacher) {
            navigate("/teacher");
          } else {
            navigate("/");
          }
        });
      });
    }
  }, []);
  

  return (
    <div className="app-container">
      <Header isEmpty={true} />
        <div className="content-wrapper">
          <Login />
        </div>
      <Footer />
    </div>
  );
};
export default AuthPage;