import React from "react";
import { Header, Footer } from "src/components";

export const PrivacyPage = () => {
  document.title = "Privacy | EasyENT";
  return (
    <div className="app-container">
      <Header isEmpty={true} />
        <div className="content-wrapper">
          <h1>Privacy</h1>
        </div>
      <Footer />
    </div>
  );
};
export default PrivacyPage;