import React from "react";
// import { useNavigate } from "react-router-dom";
import { Header, Footer } from "src/components";
// import axios from "axios";
// import { useAppSelector } from "src/hooks/redux";
// import { useSearchParams} from "react-router-dom";


export const RegNotificationPage = () => {
  document.title = "Потверждение почты";

  // const navigate = useNavigate();

  // const isLoggedIn = useAppSelector((store) => store.user.loggedIn);

  return (
    <div className="app-container">
      <Header isEmpty={true} />
      <div className="content-wrapper">
        <div className="login-container">
          <img src="assets/login.png" />
          <div className="login-form">
            <h1>Вы зарегистрировались</h1>
            <div className="login-form__des">
              <p>Для того чтобы войти, потвердите пожалуйста ваш электронный адрес.</p>
              <p>Для этого вам нужно перейти в вашу электронную почту и нажать на кнопку `Потвердить`.</p>
            </div>
          </div>
        </div>    
        {/* <Login /> */}
      </div>
      <Footer />
    </div>
  );
};
export default RegNotificationPage;