import axios from "axios";
import React, { useEffect, useState } from "react";
// import { MyCourseItem } from "src/components";
// import { useAppDispatch, useAppSelector } from "src/hooks/redux";
import { TeacherHomeworkCourses } from "src/interface";
import { Link } from "react-router-dom";

import "./TeacherHomeworkPage.scss";
import { authHeader } from "src/utils/authHeader";

export const TeacherHomeworkPage = () => {
  document.title = "ДЗ | EasyENT";
  const [allCourses, setAllCourses] = useState<TeacherHomeworkCourses[]>([]);

  
  useEffect(() => {
    axios
      .get("/api/v1/homework/by_courses",{ headers: authHeader()})
      .then((response) => {
        setAllCourses(response.data)
        console.log(response.data);
      });      
  }, []);
 
  const findExistCourse = allCourses;

  return (
    <div className="my-course">
      <h1 className="title">Выберите курс</h1>
      <ul className="subject-list">
        {findExistCourse.map((course) => (
          <li key={course.course_id}>
            <div className="box subject-hw">
              <span className="subject-hw__heading">{course.name}</span>
              <p className="subject-hw__done"><span>На проверке:</span> {course.applied_homeworks} уроков</p>
              <p className="subject-hw__done"><span>Cдано:</span> {course.complete_homeworks} уроков</p>
              <Link to={`course_${course.course_id}`} className="my-btn" >
                Подробнее <i className="fa-solid fa-angle-right"></i>
              </Link>
            </div> 
          </li>
        ))}
      </ul>
    </div>
  );
};
