import React, { FC} from "react";
// import { Link } from "react-router-dom";
// import axios from "src/utils/axios";
// import Skeleton from "react-loading-skeleton";

// import { authHeader } from "src/utils/authHeader";
import { IPenalty } from "src/interface";
// import { ProgressBar } from "src/components";
import { useTranslation } from "react-i18next";
import { namespaces } from "src/i18n/i18n.constants";
import "./Penalty.scss";

function calculateHeartLevels(heartPoints: number, penalty_count: number) {
    // Initialize an array with heart levels
    const heartLevels = [1, 1, 1];
    
    // Apply penalties
    for (let i = 0; i < penalty_count; i++) {
      // Find the first heart level that is still positive and decrement it
      for (let j = 0; j < heartLevels.length; j++) {
        if (heartLevels[j] > 0) {
          heartLevels[j] -= 1;
          break; // Reduce only one heart level per penalty
        }
      }
    }
  
    return heartLevels;
  }

export const Penalty: FC<{ penalties: IPenalty[] | undefined }> = ({
    penalties
}) => {
    const penalty_count = (penalties) ? penalties.length : 0;
    const { t} = useTranslation(namespaces.pages.hello);
    return (<>
        <div>{t("inner_course.Система_жизни", { ns: namespaces.common })} </div>
        {calculateHeartLevels(3, penalty_count).reverse().map((j) => <img key={j} src={`/assets/courses/heart-1.png`} width="25px"/>)}
        {/* {calculateHeartLevels(3, penalty_count).reverse().map((j) => <img key={j} src={`/assets/courses/heart-${j}.png`} width="25px"/>)} */}
        </>)
  };