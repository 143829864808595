import axios from "axios";
import React, { useEffect, useState } from "react";
import { authHeader } from "src/utils/authHeader";
import { useParams, Link } from "react-router-dom";
import { AddTestQuestion} from "src/components";
import "./AdminTestInner.scss";

export const AdminTestInner = () => {
  document.title = "Тесты | EasyENT";
  const params = useParams();
  const [testQuestions, setTestQuestions] = useState<any>([]);
  const [uploadPhoto, setUploadPhoto] = useState({photofile: "string",});
  const [prevQuestions, setPrevQuestions] = useState<any>([]);
  const [subjectTitle, setSubjectTitle] = useState<string>("");

  useEffect(() => {
    axios
        .get(`/api/v1/tests/admin/tests/${params.id}`, { headers: authHeader() })
        .then((response: any) => {
          setTestQuestions(response.data.questions);
        });
   axios
      .get(`/api/v1/tests/tests/${params.id}`, { headers: authHeader() })
      .then((response: any) => {
        setSubjectTitle(response.data.subject.title);
      });    
  }, [uploadPhoto]);

  const UploadQuestionPhoto = (event: React.ChangeEvent<HTMLInputElement>) => {
    const formdata = new FormData();
    const question_id = parseInt(event.target.id);
    if (event.target.files?.length) {
      formdata.append("file", event.target.files[0]);
      axios
        .post("/api/v1/tests/question/" + question_id + "/image", formdata, {
          headers: authHeader(),
        })
        .then(() => {
          setUploadPhoto({ ["photofile"]: event.target.value });
        });
    }
  };
  const onUpdateQuestion = (question: any) => {
    const newQuestion = {
      display_name: question.display_name
    }
    axios
      .put("/api/v1/tests/question/" + question.id, newQuestion, {
        headers: authHeader(),
      })
      .then(() => {
        getLessonQuestions();
      })
      .catch(() => {
        // alert(error.response.data);
      });
  };
  const onUpdateChoice = (choice:any) => {
    axios
      .put("/api/v1/test/variant/" + choice.id, choice, {
        headers: authHeader(),
      })
      .then(() => {
        getLessonQuestions();
      })
      .catch(() => {
        // alert(error.response.data);
      });
  };
  const getLessonQuestions = () => {
    axios
      .get(`/api/v1/tests/admin/tests/${params.id}`, { headers: authHeader(), })
      .then((response) => {
        setTestQuestions(response.data.questions);
        setPrevQuestions(JSON.parse(JSON.stringify(response.data.questions)));
      });
  };
  const onChangeChoiceValue = (e: React.MouseEvent<HTMLInputElement>) => {
    const element = e.currentTarget;
    const questionIndex = element.getAttribute("data-sequence");
    const questionIndexN = parseInt("" + questionIndex);
    const choidId = element.getAttribute("data-id");
    const choiceIdN = parseInt("" + choidId);
    const variants = testQuestions[questionIndexN].question.variants;
    const newChoice = variants.find((choice:any) => choice.id === choiceIdN);
    let length_iscorrect = 0;
    for (let index = 0; index < variants.length; index++) {
      if(variants[index].is_correct === true){
        console.log(variants[index].value)
        length_iscorrect+=1;
      }
    }
    console.log(length_iscorrect);
    if(length_iscorrect < 2){
      newChoice.is_correct = true;
      axios
      .put("/api/v1/tests/variant/" + choiceIdN, newChoice, {
        headers: authHeader(),
      })
      .then(() => {
        getLessonQuestions()
      });
    }
    else{
      alert("больше 2 вариантов нельзя");
    }  
  };
  const ChoiceValueToFalse = (e: React.MouseEvent<HTMLInputElement>) => {
    const element = e.currentTarget;
    const questionIndex = element.getAttribute("data-sequence");
    const questionIndexN = parseInt("" + questionIndex);
    const choidId = element.getAttribute("data-id");
    const choiceIdN = parseInt("" + choidId);
    const variants = testQuestions[questionIndexN].question.variants;
    const newChoice = variants.find((choice:any) => choice.id === choiceIdN);
    newChoice.is_correct = false;
    axios
      .put("/api/v1/tests/variant/" + choiceIdN, newChoice, {
        headers: authHeader(),
      })
      .then(() => {
        getLessonQuestions()
      });
  }
  const UploadPhoto = (event: React.ChangeEvent<HTMLInputElement>) => {
    const formdata = new FormData();
    const question_choise_id = parseInt(event.target.id);
    if (event.target.files?.length) {
      formdata.append("file", event.target.files[0]);
      axios
        .post(
          "/api/v1/tests/variant/" + question_choise_id + "/image",
          formdata,
          { headers: authHeader() }
        )
        .then(() => {
          setUploadPhoto({ ["photofile"]: event.target.value });
        });
    }
  };

  const DeleteQuestion = (e: React.MouseEvent<HTMLButtonElement>) => {
    const element = e.currentTarget;
    const questionId = element.getAttribute("data-quesiton-id");
    const questionIdnumber = parseInt("" + questionId);
    axios
      .delete("/api/v1/tests/question/" + questionIdnumber, {
        headers: authHeader(),
      })
      .then((response) => {
        if (response.status == 204 || response.status == 200) {
          alert('Вопрос успешно удален');
          window.location.reload();
        }
      })
      .catch((error) => {
        alert(error)
      });
  };
  return (
    <div className="my-course">
      <ul className="breadcrumbs">
        <li><Link to="/admin/courses">Мои тетсы</Link></li>
        <li><span><i className="fa-solid fa-arrow-right"></i></span></li>
        <li><Link to={`/admin/tests/${params.id}`}>{subjectTitle}</Link></li>
        <li><span><i className="fa-solid fa-arrow-right"></i></span></li>
        <li><span>Редактирование теста</span></li>
      </ul>
      <h1 className="title"></h1>
      <AddTestQuestion questionlength={testQuestions.length ? testQuestions.length : "0"} testid={params.id}/>
      <h1>Cписок вопросов:</h1>
      <ul className="qadmin-list">
      {testQuestions?.sort((a: any, b: any) => a?.question.sequence - b?.question.sequence)
      .map((question: any, index: any) => (
          <li key={question.id}>
            <div className="question-admin">
              <div className="question-admin__heading">
                <div className="question-admin__text">
                    {index + 1 + ")"} 
                    <input
                      value={question.question.display_name}
                      onChange={(e) => {
                        const newQuestions = [...testQuestions];
                        newQuestions[index].question.display_name = e.target.value;
                        setTestQuestions(newQuestions);
                      }}
                      onBlur={() => {
                        if (
                          prevQuestions?.[index]?.question.display_name !==
                          testQuestions?.[index]?.question.display_name
                        ) {
                          onUpdateQuestion(question.question);
                        }
                      }}
                      type="text"
                    />
                    <div className="question-admin__file">
                      <i className="fa-regular fa-image"></i>
                      <input
                        id={"" + question.question.id}
                        onChange={UploadQuestionPhoto}
                        accept="image/png, image/jpeg"
                        type="file"
                      ></input>
                    </div>
                </div>
                {question.question.image !== null ? (
                  <div className="realadmin-choice-row__img">
                    <img
                      src={"https://app.easyent.kz" + question.question.image}
                    ></img>
                  </div>
                ) : (
                  ""
                )}
              </div>
              {question.question.question_type == "choice" ? (
                <div className="question-row__area">
                {question["question"]["variants"].length > 0 ? (
                  question["question"]["variants"]
                    .sort((a: any, b: any) => a?.seq_idx - b?.seq_idx)
                    .map((choice: any, choiceIdx: any) => (
                      <div key={choice.id} className="admin-choice-row">
                        <div className="admin-choice">
                          {choice.is_correct ? (
                            <div 
                              data-sequence={index}
                              data-id={choice.id}
                              data-quesiton-id={"" + question.question.id}
                              onClick={ChoiceValueToFalse}
                              className="tipo-input"
                            >
                              <i className="fa-solid fa-check"></i>
                            </div>
                          ) : (
                            <div
                              data-sequence={index}
                              data-id={choice.id}
                              data-quesiton-id={"" + question.question.id}
                              onClick={onChangeChoiceValue}
                              className="tipo-input"
                            ></div>
                          )}
                          <div className="admin-choise__right">
                            <label htmlFor={"" + choice.id}>
                                <input
                                  value={choice.value}
                                  onChange={(e) => {
                                    const newQuestions = [...testQuestions];
                                    newQuestions[index].question.variants[
                                      choiceIdx
                                    ].value = e.target.value;
                                    setTestQuestions(newQuestions);
                                  }}
                                  onBlur={() => {
                                    if (
                                      prevQuestions?.[index]?.question.display_name !==
                                      testQuestions?.[index]?.question.display_name
                                    )  {
                                      console.log(555);
                                      onUpdateChoice(choice);
                                    }
                                  }}
                                  type="text"
                                />
                              </label>
                            <div className="question-admin__file">
                              <i className="fa-regular fa-image"></i>
                              <input
                                id={"" + choice.id}
                                onChange={UploadPhoto}
                                accept="image/png, image/jpeg"
                                type="file"
                              ></input>
                            </div>
                          </div>
                        </div>
                        {choice.image ? (
                          <div className="adminreal-choice-row__img">
                            <img
                              src={"https://app.easyent.kz/" + choice.image}
                            ></img>
                          </div>
                        ) : (
                          ""
                        )}
                      </div>
                    ))
                  ) : (
                  <div>пусто </div>
                )}
            </div>
              ) : (
                <table className="group-table">
                  {question["question"]["variants"].length > 7 ? (
                    <>
                      <tr>
                        <td>
                          {question["question"]["variants"].filter((item:any) => item.name == "value1").map((choice: any) => (
                            <p key={choice.group_name}>{choice.group_name}</p>
                          ))
                          }
                        </td>
                        <td>
                          <ul className="group-ul">
                            {
                              question["question"]["variants"]
                              .sort((a: any, b: any) => a?.name.substring(5, 6) - b?.name.substring(5, 6))
                              .slice(0, 4)
                              .map((choice: any) => (
                                <li key={choice.id} >
                                  <div className="admin-choice">
                                    {choice.is_correct ? (
                                      <div 
                                        data-sequence={index}
                                        data-id={choice.id}
                                        data-quesiton-id={"" + question.question.id}
                                        onClick={ChoiceValueToFalse}
                                        className="tipo-input"
                                      >
                                        <i className="fa-solid fa-check"></i>
                                      </div>
                                    ) : (
                                      <div
                                        data-sequence={index}
                                        data-id={choice.id}
                                        data-quesiton-id={"" + question.question.id}
                                        onClick={onChangeChoiceValue}
                                        className="tipo-input"
                                      ></div>
                                    )}
                                    <div className="admin-choise__right">
                                      <label htmlFor="124">{choice.value}</label>
                                    </div>
                                  </div>
                                </li>
                              ))  
                            }  
                          </ul>
                        </td>
                      </tr>
                      <tr>
                        <td>
                          {question["question"]["variants"].filter((item:any) => item.name == "value8").map((choice: any) => (
                            <p key={choice.group_name}>{choice.group_name}</p>
                          ))
                          }
                        </td>
                        <td>
                          <ul className="group-ul">
                            {
                              question["question"]["variants"]
                              .sort((a: any, b: any) => a?.name.substring(5, 6) - b?.name.substring(5, 6))
                              .slice(4, 8)
                              .map((choice: any) => (
                                <li key={choice.id} >
                                  <div className="admin-choice">
                                    {choice.is_correct ? (
                                      <div 
                                        data-sequence={index}
                                        data-id={choice.id}
                                        data-quesiton-id={"" + question.question.id}
                                        onClick={ChoiceValueToFalse}
                                        className="tipo-input"
                                      >
                                        <i className="fa-solid fa-check"></i>
                                      </div>
                                    ) : (
                                      <div
                                        data-sequence={index}
                                        data-id={choice.id}
                                        data-quesiton-id={"" + question.question.id}
                                        onClick={onChangeChoiceValue}
                                        className="tipo-input"
                                      ></div>
                                    )}
                                    <div className="admin-choise__right">
                                      <label htmlFor="124">{choice.value}</label>
                                    </div>
                                  </div>
                                </li>
                              ))  
                            }  
                          </ul>
                        </td>
                      </tr>
                    </>
                  ) : (
                    "Ошибка"
                  )}
                  
                </table>
              )
              }
                <button data-quesiton-id={"" + question.question.id} onClick={DeleteQuestion} className="btn btn-primary btn-inline">Удалить вопрос</button>
            </div>      
          </li>
        ))}
      </ul>
    </div>
  );
};
