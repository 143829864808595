import React, { FC } from "react";
import { ICourse, Order } from "src/interface";
import "./Modal.scss";

export const Modal: FC<{active:boolean; modalcoursesList:ICourse[], order:Order,setOrder:(any), setActive:(value:boolean) => void  }> = 
  ({active, setActive, modalcoursesList, order, setOrder}) => {
  
  const addCourse = (course_id:number, course_name:string) => {    
    const addedCourses = order.positions;   
    const addedCourselength:number = addedCourses.length;
    let saleCourseStandart = 0;
    let saleCoursePremium = 0;
    if(addedCourselength > 4){
      saleCourseStandart = 40;
      saleCoursePremium = 20;
    }
    else{
      saleCourseStandart = addedCourselength * 10;
      saleCoursePremium = addedCourselength * 5;
    }
    let coursePriceStandart = 0;
    let coursePricePremium = 0;
    coursePriceStandart = 6900 - (saleCourseStandart * 6900) / 100;
    coursePricePremium = 12000 - (saleCoursePremium * 12000) / 100;
    if(order.is_full){
      coursePriceStandart = coursePriceStandart * 7;
      coursePricePremium = coursePricePremium * 7;
    }    
    let generalPrice = 0;
    if(order['package'] == 'standard'){
      generalPrice = coursePriceStandart;
      if(order['total_amount']){
        generalPrice = order['total_amount'] + coursePriceStandart;
      }
    }
    else{
      generalPrice = coursePricePremium;
      if(order['total_amount']){
        generalPrice = order['total_amount'] + coursePricePremium;
      }  
    }    
    addedCourses.push({
      "course_id": course_id,
      "name": course_name,
      "priceStandart": coursePriceStandart,
      "pricePremium": coursePricePremium
    });
    setOrder({...order,total_amount:generalPrice, start_date:'2022-07-10', end_date: "2022-08-10",  status:"payed", positions:addedCourses} as Order);
  }     
  return (
    <div className={active ? "modal active" : "modal"} onClick={()=> setActive(false)}>
      <div className="modal__content" onClick={e => e.stopPropagation()}>
        <div className="modal__heading">Выберите курсы</div>
        <ul className="modal-kurs-list">
          {modalcoursesList.map((course) => (
            <li key={course.id} className="subject-row">
              <div className="subject-row__heading">
                {course.name}
              </div>
              <div className="subject-row__right">
                <button onClick={()=> addCourse(course.id, course.name as string)} className="subject-row__add">
                  Добавить
                  <i className="fa-solid fa-plus"></i>
                </button>
              </div>
            </li>
          ))}
        </ul>
        <button onClick={()=> setActive(false)} className="my-btn">
          Закрыть окно
          <i className="fa-solid fa-xmark"></i>
        </button> 
      </div>
    </div>
  )
}
