import React, { useEffect, useState} from "react";
import { Outlet, Navigate, useLocation } from "react-router-dom";
import { Footer, Header, Navbar } from "../components";

interface IMyProps {
  user:{
    is_teacher: boolean;
    is_superuser: boolean;
  }  
}
export const StudentLayout = ({user}:IMyProps) => {
  const [navState, setNavState] = useState<boolean>(false);
  const {pathname} = useLocation();
  useEffect(()=>{
    setNavState(false);
  },[pathname])

  if(user.is_superuser !== true && user.is_teacher !== true){    
    return (
      <>
        <Header stateNavShow={setNavState}/> 
        <Navbar stateMobShow={navState}/> 
        <div className="content">
          <div className="content__inner">
            <Outlet />  
          </div>
        </div>
        <Footer />
      </>  
    );
  }  
  else if(user.is_superuser == true){
    return <Navigate to={"/admin"} replace />;  
  }
  else{
    return <Navigate to={"/teacher"} replace />;  
  }
  
};

export default StudentLayout;