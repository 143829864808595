import axios from "axios";
import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { AdminUser } from "src/interface";
import { authHeader } from "src/utils/authHeader";
import "./AdminPage.scss";


export const AdminPage = () => {
  const [users, setUsers] = useState<AdminUser[]>([]);
  document.title = "Admin-page";
  useEffect(() => {
    axios
      .get("/api/v1/users/?skip=0&limit=5000&exist_feed_for_month=2024-01-16",{ headers: authHeader()})
      .then((response) => setUsers(response.data));
  }, []);
  const studentsCount = {
    all: 0,
    ru: 0,
    kz: 0,
    rejected: 0,
  };
  const [students, setStudents] = useState(studentsCount); 
  useEffect(() => {
    for(let i = 0; i < users.length; i++) {
      studentsCount.all += 1;
      if(users[i].lang == "ru"){
        studentsCount.ru += 1; 
      }
      else{
        studentsCount.kz += 1;
      }
    }
    setStudents(studentsCount);
  }, [users]);
  return (
    <div className="shopping-page">
      <h1 className="title">Админ панель</h1>
      <ul className="admin-ul">
        <li>
          <div className="admin-card box">
             <span className="admin-card__heading">Ученики</span>
             <div className="admin-card__row">
              <span>Общее: {students.all}</span>              
             </div>
             <div className="admin-card__row">
              <span>Ученики рус: {students.ru}</span>
             </div>
             <div className="admin-card__row">
              <span>Ученики каз: {students.kz}</span>
             </div>
             <Link className="my-btn" to="/">Подробнее</Link> 
          </div>
        </li>
      </ul>
    </div>
  );
};
