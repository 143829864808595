import axios from "axios";
import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import "./AdminOrderInner.scss";
import { authHeader } from "src/utils/authHeader";

export const AdminOrderInner = () => {
  document.title = "Ордера | EasyENT";
  const params = useParams();
  const [order, setOrder] = useState<any>({});

  useEffect(() => {
    axios
      .get(`https://app.easyent.kz/api/v1/order/${params.id}`,{ headers: authHeader()})
      .then((response) => setOrder(response.data));    
  }, []);
  return (
    <div>
      <h1 className="title">Платежа внутр</h1>
      <div className="box">
        {order ? 
          (
              <div>
                <div className="payment-row">  
                  <p className="payment-row__title">Номер заказа</p>
                  <p>{order.id}</p>
                </div>
                <div className="payment-row">  
                  <p className="payment-row__title">Курсы</p>
                  <ul>
                    {order.positions ? order.positions.map((item:any) => 
                      <li key={item.order_id}>
                        {item.course?.name}
                      </li> 
                    ) : ""}
                  </ul>
                </div>
              </div>
          )
          : ""
        }
      </div>  
    </div>
  );
};
