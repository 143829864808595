import React, { FC, useState } from "react";
import axios from "axios";
import { authHeader } from "src/utils/authHeader";

interface IEditorForm {
  handleSubmit: (e: any) => void;
  handleChangeName: (e: any) => void;
  handleChange: (e: any) => void;
  user: any;
}

export const EditorForm: FC<IEditorForm> = ({
  user,
}) => {

  const [me, setMe] = useState({
    full_name: user.full_name,
    lang: user.lang || "ru",
    phone: user.phone,
    zoom_account: user.zoom_account
  });
  const onChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const element = e.target as HTMLInputElement;
    const value = element.value;
    const name = element.name;
    setMe((s) => ({ ...s, [name]: value }));
  };
  
  const onSubmit = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    axios
    .put('/api/v1/users/me', me, { headers: authHeader()})
      .then((response) => {
        console.log(response.data);
        if(response.status == 200) {            
          console.log(response);
        }
      })
      .catch(error => {
        alert(error.response.data);
        window.location.reload();
      });
  };


  return (
    <form onSubmit={onSubmit} className="editor-wrapper">
      <div className="edit-form-row">
        <label>ФИО:</label>
        <input
          className="input"
          placeholder="ФИО"
          required
          name="full_name"
          type="text"
          onChange={onChange}
          defaultValue={user.full_name}
          onInput={(e) => {
            const element = e.target as HTMLInputElement;
            if (/[a-zA-Z]/.test(element.value)) {
              element?.setCustomValidity("Введите символы на кириллице");
            } else {
              element?.setCustomValidity("")
            }
          }}
        />
      </div>
      <div className="edit-form-row">
        <label>Почтовый адрес:</label>
        <input
          className="input"
          placeholder="Почтовый адрес"
          name="postalCode"
          required
          type="text"
          readOnly
          defaultValue={user.email}
        />
      </div>
      <div className="edit-form-row">
        <label>Телефон:</label>  
        <input
          className="input"
          placeholder="Номер телефона"
          required
          type="text"
          onChange={onChange}
          name="phone"
          defaultValue={user.phone}
        />
      </div>
      <div className="edit-form-row">  
        <label>Аккаунт в Телеграме:</label>  
        <input
          className="input"
          placeholder="Логин в телеге"
          name="zoom_account"
          type="text"
          onChange={onChange}
          defaultValue={user.zoom_account}
      />
      </div>
      <div className="btn-group">
        <button className="btn btn-primary" type="submit">
          Сохранить изменения<i className="fa-solid fa-floppy-disk"></i>
        </button>
        <button className="btn btn-outlined" type="button">
          Изменить пароль<i className="fa-solid fa-pen-to-square"></i>
        </button>
      </div>
    </form>
  );
};
