import axios from "src/utils/axios";
import { authHeader } from "src/utils/authHeader";

export const userApi = {
  fetchMe: (): Promise<any> =>
    axios.get(`/api/v1/users/me`, { headers: authHeader() }),
  fetchById: (): Promise<any> =>
    axios.get(`/api/v1/users/me`, { headers: authHeader() }),
  auth: (email: string, password: string, client_id: string): Promise<any> =>
    axios
      .post(`/api/v1/login/access-token`, {
        grant_type: "password",
        username: email,
        password: password,
        client_id: client_id
      })
      .then((user) => {
        localStorage.setItem("user", JSON.stringify(user.data));
        return user;
      }),
  forgetpassword: (email: string): Promise<any> =>axios.post(`/api/v1/users/forgot-password`, { email: email })
};
