import React, { useEffect, useState } from "react";
import axios from "axios";
import FullCalendar from "@fullcalendar/react";
import ruLocale from "@fullcalendar/core/locales/ru";
import kzLocale from "@fullcalendar/core/locales/kk";
import dayGridPlugin from "@fullcalendar/daygrid";
import { EventInput } from "@fullcalendar/common";
import { authHeader } from "src/utils/authHeader";
import { ModalPhoto } from "src/components";
import "./CalendarPage.scss";

export const CalenderPage = () => {
  document.title = "Календарь | EasyENT";
  const [language, setLanguage] = useState<any>("");
  useEffect(() => {
    const mylanguage = localStorage.getItem('language');
    if(typeof mylanguage !== 'undefined' && mylanguage !== null){
      setLanguage(JSON.parse(mylanguage));
    }
  }, []);
  const [newEvents, setNewEvents] = useState<EventInput[]>([]);
  const [modalActive, setModalActive] = useState(false);
  const [width, setWidth] = useState<number>(window.innerWidth);

  function handleWindowSizeChange() {
    setWidth(window.innerWidth);
  }
  useEffect(() => {
    window.addEventListener("resize", handleWindowSizeChange);
    return () => {
      window.removeEventListener("resize", handleWindowSizeChange);
    };
  }, []);

  useEffect(() => {
    axios
      .get(
        "/api/v1/calendar/?start_date=2021-01-01&end_date=2025-01-01&skip=1&limit=800",
        { headers: authHeader() }
      )
      .then((response) => {
        setNewEvents(response.data);
      });
  }, []);

  useEffect(() => {
    if (width < 1200) {
      const dayGridDay: any = document.getElementsByClassName(
        "fc-dayGridDay-button"
      )[0];
      dayGridDay.click();
    }
  }, [width]);

  return (
    <div>
      <div className={language == "ru" ? "calendar-part" : "calendar-part сalendar-part__kaz"}>
        <FullCalendar
          plugins={[dayGridPlugin]}
          locale={language == "ru" ? ruLocale : kzLocale}
          expandRows={true}
          timeZone={"Asia/Almaty"}
          eventTimeFormat={{
            hour: "numeric",
            minute: "2-digit",
            omitZeroMinute: false,
          }}
          fixedWeekCount={false}
          headerToolbar={{
            left: "calendar-h1",
            center: "",
            right: "dayGridMonth,dayGridWeek,dayGridDay title prev,next",
          }}
          events={newEvents}
        />
      </div>
      <ModalPhoto active={modalActive} setActive={setModalActive} />
    </div>
  );
};
