import axios from "axios";
import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";

import "./AdminTestsPage.scss";
import { authHeader } from "src/utils/authHeader";

export const AdminTestsPage = () => {
  document.title = "Тесты | EasyENT";
  const [tests, setTests] = useState<any>([]);

  useEffect(() => {
    axios
      .get("/api/v1/tests/tests/?skip=0&limit=50", { headers: authHeader() })
      .then((response) => setTests(response.data));
  }, []);

  return (
    <div className="my-course">
      <h1 className="title">Тесты</h1>
      <div className="admintop-buttons">
        <Link className="btn btn-primary" to="add">Добавить тест +</Link>
      </div>  
      <table className="table">
        <tbody>
          <tr>
            <th>Id</th>
            <th>Предмет</th>
            <th>Язык</th>
            <th>Дата</th>
            <th>Управление</th>
          </tr>
          {tests.map((test: any) => (
            <tr key={test.id}>
              <td>{test.id}</td>
              <td>{test.subject.title}</td>
              <td>{test.lang}</td>
              <td>{test.write_date}</td>
              <td>
                  <Link className="btn btn-primary btn-flex" to={"/admin/tests/" + test.id}>
                      <i className="fa-solid fa-pen"></i>
                  </Link>
              </td>
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
};
