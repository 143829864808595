import React, { useEffect, useRef } from "react";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import { Header, Footer } from "src/components";
import { useSearchParams } from "react-router-dom";
import { useAppSelector } from "src/hooks/redux";



export const ResetPasswordPage = () => {
  document.title = "Cброс пароля | EasyENT";
  const navigate = useNavigate();
  const [searchParams,] = useSearchParams();
  const isLoggedIn = useAppSelector((store) => store.user.loggedIn);
  const InputPassword = useRef<HTMLInputElement>(null);

  const onSubmit = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    axios.post(`/api/v1/users/reset-password`, {token: searchParams.get('token'), password: InputPassword?.current?.value}).then((res: any) => {
      if (!res?.error?.message) {
        navigate("/");
      }
    });
  };


  useEffect(() => {
    if (isLoggedIn) {
      navigate("/calendar");
    }
  }, []);

  return (
    <div className="app-container">
      <Header isEmpty={true} />
      <div className="content-wrapper">
        <div className="login-container">
          <img src="assets/login.png" />
          <form className="login-form" onSubmit={onSubmit}>
            <h1>Восстановление пароля</h1>
            <p className="login-form__forget">Укажите пароль.</p>
            <input
              type="text"
              name="password"
              className="login-form__input"
              ref={InputPassword}
              defaultValue=""
              required
              placeholder="Новый пароль"
            />              
            <button className="btn btn-primary btn-flex" type="submit">
              Отправить <i className="fa-solid fa-right-to-bracket"></i>
            </button>
          </form>
        </div>
      </div>
      <Footer />
    </div>
    
  );
};
export default ResetPasswordPage;