import axios from "axios";
import { authHeader } from "src/utils/authHeader";
import React, { useEffect, useState } from "react";
import { MyCourseItem } from "src/components";
import { ICourse } from "src/interface";
import { useTranslation } from "react-i18next";
import { namespaces } from "src/i18n/i18n.constants";
import "./MyCoursesPage.scss";

export const MyCourses = () => {
  document.title = "Мои Курсы | EasyENT";
  const [allCourses, setAllCourses] = useState<ICourse[]>([]);
  const { t } = useTranslation(namespaces.pages.hello);
  useEffect(() => {
    axios
      .get("/api/v1/courses/my/",{ headers: authHeader()})
      .then((response) => setAllCourses(response.data));
  }, []);
 
  const findExistCourse = allCourses;

  return (
    <div className="my-course">
      <h1 className="title">{t("navbar.Мои_курсы", { ns: namespaces.common })}</h1>
      <ul className="course-spisok">
        {findExistCourse.map((course) => (
          <li key={course.id}>
            <MyCourseItem course={course} showLessons={false}  />
          </li>
        ))}
      </ul>
    </div>
  );
};
