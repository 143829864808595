import axios from "axios";
import React, { useEffect, useState } from "react";
import { LeaderUser, ICourse } from "src/interface";
import { useParams, Link, useLocation } from "react-router-dom";
import moment from "moment";
import "moment/locale/ru";
moment.locale("ru");
import { authHeader } from "src/utils/authHeader";

export const AdminUsersByCourse = () => {
  document.title = "Курсы | EasyENT";
  const params = useParams();
  const [users, setUsers] = useState<LeaderUser[]>([]);
  const [course, setCourse] = useState<ICourse>();
  const location: any = useLocation();

  useEffect(() => {
    axios
      .get("/api/v1/courses/" + params.course_id, { headers: authHeader() })
      .then((response) => {
        setCourse(response.data);
      });
  }, []);
  useEffect(() => {
    if (course) {
      axios
        .get(
          "/api/v1/courses/" +
            course.id +
            "/leader-board?in_feed_date=2023-09-11",
          { headers: authHeader() }
        )
        .then((response) => setUsers(response.data));
    }
  }, [course]);

  return (
    <div>
      <ul className="breadcrumbs">
        <li>
          <Link to="/admin/courses">Мои курсы</Link>
        </li>
        <li>
          <span>
            <i className="fa-solid fa-arrow-right"></i>
          </span>
        </li>
        <li>
          <Link to={course ? `/admin/courses/course/${course.id}` : ""}>
            {course ? course.name : "загрузка"}
          </Link>
        </li>
        <li>
          <span>
            <i className="fa-solid fa-arrow-right"></i>
          </span>
        </li>
        <li>
          <span>Ученики</span>
        </li>
      </ul>
      <h1 className="title">{course ? course.name : "загрузка"}</h1>
      <ul className="course-nav">
        <li>
          <Link
            className="course-nav__link"
            to={
              course
                ? `/admin/courses/course/${course.id}/homeworks_by_lesson/${location?.state?.lesson_id}`
                : ""
            }
          >
            Уроки
          </Link>
        </li>
        <li>
          <Link
            className="course-nav__link active"
            to={
              course
                ? `/admin/courses/course/${course.id}/users_by_course/${course.id}`
                : ""
            }
          >
            Ученики
          </Link>
        </li>
      </ul>
      <div className="select-part">
        <label className="select-part__heading">Сортировать:</label>
        <select>
          <option>Лучшие ученики</option>
          <option>Худшие ученики</option>
          <option>По новинке</option>
        </select>
      </div>
      <table className="table">
        <tbody>
          <tr>
            <th style={{ width: "40px" }}>Id</th>
            <th style={{ width: "220px" }}>Фио</th>
            <th style={{ width: "140px" }}>Номер телефона</th>
            <th style={{ width: "90px" }}>Статус</th>
            <th style={{ width: "90px" }}>Коины</th>
            <th style={{ width: "130px" }}>Успеваемость</th>
          </tr>
          {users.map((user) => (
            <tr key={user.user_id}>
              <td style={{ width: "40px" }}>{user.user_id}</td>
              <td style={{ width: "220px" }}>{user.full_name}</td>
              <td style={{ width: "140px" }}>{user.phone}</td>
              <td style={{ width: "140px" }}>
                {user.status_student !== true ? "отказ" : ""}
              </td>
              <td style={{ width: "90px" }}>{user.coins.toFixed(2)}</td>
              <td style={{ width: "130px" }}>
                <div className="item-control"></div>
              </td>
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
};
