import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import axios from "src/utils/axios";
import { useAppSelector } from "src/hooks/redux";
import { authHeader } from "src/utils/authHeader";
import { Preloader, ProgressBar } from "src/components";
import "./ParentPage.scss";


export const ParentPage = () => {
  document.title = "Тесты | EasyENT";
  const user = useAppSelector((store) => store.user.data);
  const [loadingCourses, setLoadingCourses] = useState(false);
  const [activeChild, setActiveChild] = useState<any>({});
  const [childFeeds, setChildFeeds] = useState([]);
  const [childCourses, setChildCourses] = useState<any>([]);
  const [progress, setProgress] = useState<number | null>(null);
  const navigate = useNavigate();

  function getCourseProgress(user_id: number) {
    setLoadingCourses(true);
    axios
      .get(`/api/v1/users/userprogress?user_id=${user_id}`, {
        headers: authHeader(),
      })
      .then((res: any) => {
        setChildCourses(
          childFeeds?.map((feed: any) => ({
            ...feed,
            ...res?.data?.[feed?.course_id],
          }))
        );
      })
      .finally(() => setLoadingCourses(false));
  }

  useEffect(() => {
    if (user?.children?.length) {
      setActiveChild(user?.children?.[0]);
    }
  }, [user]);

  useEffect(() => {
    if (activeChild?.id && activeChild?.user_feeds?.length) {
      setChildFeeds(
        activeChild?.user_feeds?.filter(
          (e: any, i: any) =>
            activeChild?.user_feeds?.findIndex(
              (a: any) => a["course_id"] === e["course_id"]
            ) === i
        )
      );
    }
  }, [activeChild]);

  useEffect(() => {
    if (childFeeds?.length) {
      getCourseProgress(activeChild?.id);
    }
  }, [childFeeds]);

  useEffect(() => {
    if (childCourses?.length) {
      setProgress(
        Math.round(
          childCourses?.reduce(
            (acc: number, cur: any) =>
              cur?.course_id !== 489 ? acc + cur?.progress : acc,
            0
          ) / childCourses?.length
        )
      );
    }
  }, [childCourses]);

  return (
    <div className="parent-page">
      <h1 className="title">Мой ребенок</h1>
      <div style={{ marginBottom: 36 }}>
        {user?.children?.length ? (
          user?.children?.map((child: any) => (
            <button
              key={child?.id}
              type="button"
              className={`btn ${
                activeChild?.id === child?.id ? "btn-primary" : "btn-outlined"
              }`}
              onClick={() => {
                setActiveChild(child);
              }}
            >
              {child?.full_name}
            </button>
          ))
        ) : (
          <Preloader />
        )}
      </div>
      <div className="card-progress">
        {progress == null ? (
          <Preloader />
        ) : (
          <>
            <h3>Общая успеваемость: {progress}%</h3>
            <ProgressBar completed={progress} />
          </>
        )}
      </div>
      <h1 className="sub-title">Успеваемость по курсам</h1>
      <div className="card-container">
        {loadingCourses ? (
          <Preloader />
        ) : (
          childCourses?.length &&
          childCourses?.map((course: any) => (
            <div key={course?.id} className="card">
              <h4>{course?.course_name}</h4>
              <div>Успеваемость: {Math.round(course?.progress)}</div>
              <div>Выполнено ДЗ: {course?.completed_homework}</div>
              <div>Средний балл: {Math.round(course?.average_rating)}</div>
              <button
                type="button"
                className="btn btn-primary"
                onClick={() => {
                  navigate("/parent/child-course", {
                    state: {
                      user_full_name: activeChild?.full_name,
                      course_name: course?.course_name,
                      user_id: course?.user_id,
                      course_id: course?.course_id,
                    },
                  });
                }}
              >
                Подробнее
              </button>
            </div>
          ))
        )}
      </div>
    </div>
  );
};
