import { toast as t } from "react-toastify";

export default function toast(
  message: string,
  type: "info" | "success" | "warning" | "error"
) {
  t(message, {
    type,
    position: "top-right",
    autoClose: 3000,
    hideProgressBar: true,
    closeOnClick: true,
    pauseOnHover: true,
    draggable: true,
    progress: undefined,
    theme: "colored",
  });
}
