import axios from "axios";
import React, { useEffect, useState } from "react";
import { useParams, useSearchParams } from "react-router-dom";
import { useAppSelector } from "src/hooks/redux";
import { User_feeds, OrderShort } from "src/interface";
import { authHeader } from "src/utils/authHeader";


export const TransactionSuccessPage = () => {
  document.title = "Мои оплаты | EasyENT";
  const [userFeeds, setUserFeeds] = useState<User_feeds[]>([]);
  const params = useParams();
  const [searchParams, ] = useSearchParams();
    
  let orderId = searchParams.get("id");
  if (params.id) {
    orderId = params.id;
  }
  const user = useAppSelector((store) => store.user.data);
  useEffect(() => {
    if(!user.id){
      console.log('пустo')
    }
    else{
      setUserFeeds(user.user_feeds);
    }
  }, []);
  const [order, setOrder] = useState<OrderShort>();
  const [isLoading, setIsLoading] = useState(true);
  useEffect(() => {
    if(!orderId){
      console.log('пустo')
    }
    else{
      setUserFeeds(user.user_feeds);
      axios
      .get(`/api/v1/order/${orderId}`,{ headers: authHeader()})
      .then((response) => {
        setOrder(response.data);
      }).finally(()=> {
        setIsLoading(false);
      });
    }
  }, [orderId]);
  // const subscriptions = useAppSelector(
  //   (store) => store.user.data.subscriptions
  // );
  const StatusTab = () => {
    // alert(orderId)
    return (
      <>
        <div className="payment-item">
          <div className="payment-row">  
            <p className="payment-row__title">Номер заказа</p>
            <p>{order ? order.id : "Загрузка"}</p>
          </div>
          <div className="payment-row">  
            <p className="payment-row__title">Мои курсы</p>
            <ul>
              {
                userFeeds.map((item) => item.start_date == "2023-09-11" ? 
                <li key={item.start_date}>{item.course_name}</li> : 
                ""
              )}
            </ul> 
          </div>
          <div className="payment-row">  
            <p className="payment-row__title">Стоимость</p>
            <p>{order ? order.total_amount : "Загрузка"}</p>
          </div>
          <div className="payment-row">    
            <p className="payment-row__title">Статус</p>
              { userFeeds.filter(e => e.start_date == "2023-10-11").length > 0 ?
                <p><i className="fa-solid fa-check"></i>Оплачено</p> : <p><i className="fa-solid fa-xmark"></i> Не оплачено</p>
              }
          </div>
            {
              order && userFeeds.filter(e => e.start_date == order.start_date).length < 1 ?  
              <div>
                <form target="_blank" method="post" action="https://kaspi.kz/online" id="formOnlinePayment">
                    <input type="hidden" name="TranId" value={order.id + ""}/>
                    <input type="hidden" name="OrderId" value={order.id + ""}/>
                    <input type="hidden" name="Amount" value={(order.total_amount * 100) + ""}/>
                    <input type="hidden" name="Service" value="StayStudy"/>
                    <input type="hidden" name="ReturnUrl" value={"https://app.easyent.kz/transaction/success?id="+order.id}/>
                    <button className="btn btn-primary btn-flex">Оплатить через Kaspi</button>
                </form>
                <div className="payment-info">
                  <span className="payment-info__heading2">Если оплата не выходит при нажатии на кнопку оплатить</span>
                  <ul className="step-ul">  
                      <li>Войдите в раздел Мои платежи, в kaspi приложении</li> 
                      <li>Введите в поиске EasyENT</li> 
                      <li>Перейдите в раздел EasyENT, образование</li>
                      <li>Введите номер заказа: <b>{order ? order.id : "Загрузка"}</b></li>
                      <li>Оплатите заказ</li>
                  </ul>
                  <p>Так же вы можете отправить данную инструкцию, своим <b>родителям</b></p> 
                </div>
                <div className="payment-info">
                  <span className="payment-info__heading2">Если цена неверная напишите</span>
                  <p>Напишите пожалуйста на номер: + 7 705 677 12 50 (Куралай)</p>
                </div>  
              </div> : "" 
            }
        </div>
      </>
    )
  }
  
  return (
    <div className="subscription-content">
      <h1>Статус оплаты</h1>
      {!order && isLoading && <h4>Загрузка</h4>}
      {!order && !isLoading && <h4>Заказ не найден</h4>}
      {order && <StatusTab/>}
    </div>
  );
};
