import React, { useEffect, useState } from "react";
import axios from "axios";
import { Modal } from "src/components";
import { authHeader } from "src/utils/authHeader";
import "./BuyCoursesPage.scss";
import { Order, PackageCourse, ICourse } from "src/interface";

export const BuyCoursesPage = () => {
  document.title = "Покупка курсов | EasyENT";
  const [modalActive, setModalActive] = useState(false);
  const [coursesList, setCoursesList] = useState<ICourse[]>([]);
  const JsonOrder = localStorage.getItem('order');
  const Myorder = (JsonOrder !== null) ? JSON.parse(JsonOrder) : null;
  const [order, setOrder] = useState<Order>(Myorder);
  const [modalCourses, setmodalCourses] = useState<ICourse[]>([]);

  useEffect(() => {
    localStorage.setItem('order', JSON.stringify(order)); 
    const newmodalCourses2 = coursesList.length > 0 ? (
      coursesList.filter(item => {
        return !order['positions'].find(addedItem => {
          return item.id === addedItem.course_id;
        });
      })
    ) :coursesList;    
    setmodalCourses(newmodalCourses2) 
  }, [order]);  

  useEffect(() => {
    axios
      .get("/api/v1/courses",{ headers: authHeader()})
      .then((response) => {
        setCoursesList(response.data);
        const courseslist:ICourse[] = response.data;
        const newmodalCourses = courseslist.length > 0 ? (
          courseslist.filter(item => {
            return !order['positions'].find(addedItem => {
              return item.id === addedItem.course_id;
            });
          })
        ) : courseslist;
        setmodalCourses(newmodalCourses)        
      })      
  }, []);

  const setPackage = (packageType:PackageCourse) => {
    let generalPrice;
    const courseArray = order.positions; 
    if(packageType == 'standard'){         
      generalPrice = courseArray.reduce<number>((a, b) => {
        if(b['priceStandart'] !== undefined){
          return a + b['priceStandart'];
        }
        else{
          return a + 0;
        }
      }, 0);
    }
    else{
      generalPrice = courseArray.reduce<number>((a, b) => {
        if(b['pricePremium'] !== undefined){
          return a + b['pricePremium'];
        }
        else{
          return a + 0;
        }
      }, 0);
    } 
    setOrder({...order,total_amount: generalPrice, package: packageType, })
  }
  const removeCourse = (e:number) => {
    let standartPrice = 6900; 
    let premiumPrice = 12000;
    if(order.is_full){
      standartPrice = standartPrice * 7;
      premiumPrice = premiumPrice * 7;
    } 
    let saleCourseStandart = 0;
    let saleCoursePremium = 0;
    let generalPrice = 0;
    const newOrder = order.positions.reduce(function(filtered:typeof option[], option,index){
      if(option.course_id !== e){
          const newStandartPrice = standartPrice - (saleCourseStandart * standartPrice) / 100;
          const newPremiumPrice = premiumPrice - (saleCoursePremium * premiumPrice) / 100;
          if(order.package == PackageCourse.standart){
            generalPrice += newStandartPrice;
          }
          else{
            generalPrice += newPremiumPrice;
          }
          const newValue = {
            course_id: option.course_id,
            name: option.name,
            priceStandart: newStandartPrice,
            pricePremium: newPremiumPrice,
          }           
          filtered.push(newValue);
          if(index < 5){
            saleCourseStandart += 10;
            saleCoursePremium += 5;
          }
      }
      return filtered;
    },[]);
    setOrder({...order, total_amount: generalPrice, positions: newOrder} as Order)
  }
  console.log(order);
  const handlePayment = (paymentType:string) => {
    let generalPrice = 0;
    if(paymentType == 'month'){
      const newOrderPositions = order.positions.map((item)=>{
        if(item.priceStandart !== undefined && item.pricePremium !== undefined){          
          if(order.package == PackageCourse.standart){
            generalPrice += item.priceStandart / 7;
          }
          else{
            generalPrice += item.pricePremium / 7;
          }
          return ({
            ...item,
            priceStandart:item.priceStandart / 7,
            pricePremium:item.pricePremium / 7,
          });
        }        
      });
      setOrder({...order, total_amount:generalPrice, is_full:false, positions: newOrderPositions, start_date: '2022-07-10', end_date: "2022-08-10"} as Order)
    }
    else if(paymentType == 'year'){
      const newOrderPositions = order.positions.map((item)=>{
        if(item.priceStandart !== undefined && item.pricePremium !== undefined){          
          if(order.package == PackageCourse.standart){
            generalPrice += item.priceStandart * 7;
          }
          else{
            generalPrice += item.pricePremium * 7;
          }
          return ({
            ...item,
            priceStandart:item.priceStandart * 7,
            pricePremium:item.pricePremium * 7,
          });
        }        
      });
      setOrder({...order, total_amount:generalPrice, is_full:true, positions: newOrderPositions, start_date: '2022-07-10', end_date: "2023-08-10"} as Order)
    }
  }
  
  const orderNew = () => {    
    const newPositions = order.positions.map(item=>{
      return {
        course_id: item.course_id,
        start_date: order.start_date,
        end_date: order.end_date, 
      }
    });
    // const packageName = order.package == PackageCourse.standart ? 'standard' : 'premium';    
    const newOrder = {
      "total_amount": order.total_amount,
      "start_date": order.start_date,
      "end_date": order.end_date,
      "is_full": order.is_full,
      "status": 'payed',
      "package": order.package,
      "positions": newPositions
    } 
    console.log(newOrder);     
    if(order['total_amount']){      
      axios
      .post('/api/v1/order/', newOrder,{ headers: authHeader()})
        .then((response) => {
          console.log(response.data);
          if(response.status == 200) {            
            console.log(response);
            axios.get('/api/v1/order/'+response.data.id+'/payment_link',{ headers: authHeader()}).then(kassa_resp=>{
              window.location.assign(kassa_resp.data.url);
            })
          }
        })
        .catch(error => {
          alert('ошибка');
          console.log(error.response.data);
         });
    }
    else{
      alert('заполните все поля');
    }
  }
  return (
    <>
      <h1 className="title">Покупка курсов</h1>
      {Myorder ? (
        <div className="buy-course box">
        <div className="buy-row">
          <span className="buy-row__heading buy-row__heading--pack">
            Тип курса:
          </span>
          <div className="buy-row__area buy-row__area--flex">
           <div onClick={() => setPackage(PackageCourse.standart)} className={ order['package'] == 'standard' ? "buy-row__pack buy-row__pack--active" : "buy-row__pack"}>
              <label>Стандарт</label>
              <input readOnly checked = {order['package'] == 'standard' ? true : false} name="package" type="radio"></input>
              <i className="fa-solid fa-angle-right"></i>
            </div>
            <div onClick={() => setPackage(PackageCourse.premium)} className={ order['package'] == 'premium' ? "buy-row__pack buy-row__pack--active" : "buy-row__pack"}>
              <img src="/assets/svg/premium.svg"/>
              <label>Премиум</label>
              <input readOnly checked = {order['package'] == 'premium' ? true : false} name="package" type="radio"></input>
              <i className="fa-solid fa-angle-right"></i>
            </div>
          </div>
        </div>
        <div className="buy-row">
          <span className="buy-row__heading">
            Выберите курсы:
          </span>
          <div className="buy-row__area">
            <ul className={order['positions'].length > 0 ? "buy-list" : "buy-list buy-list--empty"}>
              {order.positions.map((course) => (
                <li key={course.course_id} className="buy-list__row">
                  <span>{course.name}</span>
                  <div className="buy-list__right">
                    <div className="buy-list__price">
                      <span>₸ {order.package == 'standard' ? course.priceStandart : course.pricePremium}  </span> / {order['is_full'] ? "в год" : "месяц"}
                    </div>
                    <button onClick={() => removeCourse(course.course_id as number)} className="buy-list__del">Убрать <i className="fa-solid fa-xmark"></i></button>
                  </div>
                </li>
              ))}  
            </ul>
            <div className="add-course">
              <button onClick={()=> setModalActive(true)} className="add-course__button">
                Добавить курс
                <i className="fa-solid fa-plus"></i>
              </button>
              <Modal modalcoursesList={modalCourses} order={order} setOrder={setOrder}  active={modalActive} setActive={setModalActive}/>
              <div className="add-course__text">При добавлении курса вы получите скидку -10%</div>
            </div>
          </div>
        </div>
        <div className="buy-row">
          <span className="buy-row__heading">
            Способо оплаты:
          </span>
          <div className="buy-row__area buy-row__area--flex">
            <div className="buy-row__radio">
              <input  checked = {order['is_full'] ? false : true} onChange={() => handlePayment('month')} id="payment_month" name="payment-type" type="radio"></input>
              <label htmlFor="payment_month">Ежемесячная оплата</label>
            </div>
            <div className="buy-row__radio">
              <input checked = {order['is_full']} onChange={() => handlePayment('year')} id="payment_year" name="payment-type" type="radio"></input>
              <label htmlFor="payment_year">Оплатить за весь курс</label>
            </div>  
          </div>
        </div>
        <div className="generalPrice">
          <div className="buy-row">
            <span className="buy-row__heading">
              Общая цена:
            </span>
            <div className="buy-row__area">
              <div className="buy-row__final">
                <div><span>₸ {order['total_amount']}</span> / {order['is_full'] ? "в год" : "месяц"} </div> 
              </div>
            </div>
          </div>
          <div className="buy-final">
              <button onClick={orderNew.bind(this)} className="my-btn">
                Оплатить
                <i className="fa-solid fa-angle-right"></i>
              </button>
            <p>Нажимая на кнопку Оплатить, вы выражаете свое согласие c <span>офертой оказания услуг</span> и принимаете их условия</p>
          </div>
        </div>
      </div>
      ) : (
        <div>Покупки пустые</div>
      )}
    </>
  );
};
