import React from "react";
import { ProfileEditor } from "src/components";

import "./MyProfilePage.scss";

export const MyProfilePage = () => {
  document.title = "Мой Профиль | EasyENT";
  return (
    <div className="profile-container">
      <ProfileEditor />
    </div>
  );
};
