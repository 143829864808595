import axios from "axios";
import React, { useEffect, useState } from "react";
import { ICourse } from "src/interface";
import { useParams, Link } from "react-router-dom";
import moment from "moment";
import "moment/locale/ru";
moment.locale("ru");
import "./UserActivity.scss";
import { authHeader } from "src/utils/authHeader";

export const UserActivity = () => {
  document.title = "Успеваемость ученика | EasyENT";
  const params = useParams();
  const [leader, setLeader] = useState<any>([]);
  const [course, setCourse] = useState<ICourse>();
  const lesson_number = 0;
    useEffect(() => {
      axios
      .get("/api/v1/courses/" + params.course_id, { headers: authHeader() })
      .then((response) => {
        setCourse(response.data);
      });
    }, []);
  useEffect(() => {
    if(course){
      axios
      .get("/api/v1/homework/getusershomeworks?course_id=246&user_id=7",{ headers: authHeader()})
      .then((response) => setLeader(response.data));
      }
  }, [course]);  
  return (
    <div>
      <ul className="breadcrumbs">
        <li>
          <a href="/teacher">Мои курсы</a>
          </li>
        <li>
          <span><i className="fa-solid fa-arrow-right"></i></span>
        </li>
        <li>
          <a href={course ? `/teacher/course_${course.id}` : ""}>{course ? course.name : "загрузка"}</a>
        </li>
        <li>
          <span><i className="fa-solid fa-arrow-right"></i></span>
        </li>
        <li>
          <span>Ученики</span>
        </li>
      </ul>
      <h1 className="title">{course ? course.name : "загрузка"}</h1>
      <ul className="course-nav">
        <li>
          <Link className="course-nav__link" to={course ? `/teacher/course_${course.id}` : ""}>Уроки</Link>
        </li>
        <li>
          <Link className="course-nav__link active" to={course ? `/teacher/course_${course.id}/users_by_course/${course.id}` : ""}>Ученики</Link>
        </li>
      </ul>
      <div className="select-part">
        <label className="select-part__heading">Сортировать:</label>
        <select>
          <option>Лучшие ученики</option>
          <option>Худшие ученики</option>
          <option>По новинке</option>
        </select>
      </div>
      <table className="table">
        <tbody>
        <tr>
          <th style={{width: '200px'}}>
            Номер урока
          </th>
          <th>
            Название
          </th>          
          <th style={{width: '140px'}}>
            Статус
          </th>
          <th style={{width: '200px'}}>
            Управление
          </th>
        </tr>
        {leader.map((homework:any) => (
          <tr key={homework.id}>
            <td style={{width: '200px'}}>
              {lesson_number + 1}
            </td>
            <td>
             {homework.name}
            </td>
            <td style={{width: '140px'}}>
              {homework.count_of_homework} 
            </td>
            <td style={{width: '200px'}}>
              <div className="item-control">
                  Подробнее
              </div>
            </td>
          </tr> 
        ))}
        </tbody>
      </table>
    </div>
  );
};
