import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { Tabs } from "react-simple-tabs-component";
// import { useAppSelector } from "src/hooks/redux";
import { useTranslation } from "react-i18next";
import { namespaces } from "src/i18n/i18n.constants";
import "./TournamentPage.scss";

export const TournamentPage = () => {
  document.title = "Интернет-магазин | EasyENT";
  const { t } = useTranslation(namespaces.pages.hello);
  // const user = useAppSelector((store) => store.user.data);
  const [language, setLanguage] = useState<any>("");
  useEffect(() => {
    const mylanguage = localStorage.getItem('language');
    if(typeof mylanguage !== 'undefined' && mylanguage !== null){
      setLanguage(JSON.parse(mylanguage));
    }
  }, []);
  const TabSeptember = () => {
    return (
      <>
        <table className="tour-table">
          <tbody>
            <tr>
              <th>
                <img src="assets/svg/tour.svg"></img>
              </th>
              <th>
                <img src="assets/svg/tour-name.svg"></img>
              </th>
              <th>
                <img src="assets/svg/tour-money.svg"></img>
              </th>
              <th>
                <img src="assets/svg/tour-user.svg"></img>
              </th>
            </tr>
            <tr>
              <td>
                <span className="tour-table__name">Тауекель хан</span>
                <span className="tour-table__name">Абылай хан</span>
              </td>
              <td><p>История Казахстана</p></td>
              <td><p>25</p> <p>25</p></td>
              <td>
                <p className="tour-table__users">Кантарбаев Санжар, Сипатов Даниэль Тимурович, Алиппек Арайлым, Ауылбек Айсулу Камалжанкызы, Абишева Дильназ Айдаровна, Музартбекова Анеля Нургалиевна, Тулеува Дильназ Ермекова, Сарсенова Альбина Бердибековна, Жиеналина Камила Бериковна, Имашева Зарина, Калас Кайырбек Кайырбекулы, Исабекова Аружан</p>
                <p className="tour-table__users">Гладченко Ольга, Алдияр Масанов Салайулы, Меньшина Ясмина Кирриловна, Ибраев Назар, Ермагамбетова Камила, Каржас Асия, Петцке Анна, Орынбасар Ерсултан Ержанулы, Арсений хван Николаевич, Гладченко Ольга , Туранова Жасмин, Алия Кулбаева</p>
              </td>
            </tr>
            <tr>
              <td>
                <span className="tour-table__name">Формула Успеха</span>
              </td>
              <td><p>Мат. грамотность</p></td>
              <td><p>50</p></td>
              <td>
                <p className="tour-table__users">Ни Даниэль, Койшибекова Жания, Амиргажы Арайлым, Баракбай Айым, Гейн Марк,Жумаш Аяулым, Лепольд Кристина, Баязитова Алина, Альмуханова Акнур, Бахтиева София, Исахан Арлан, Алиева Лэйла, Бисахметова Айгерим, Воронова Вероника, Амраева Малика, Тенесбаева Сара</p>
              </td>
            </tr>
            <tr>
              <td>
                <span className="tour-table__name">Матем Магнаты</span>
              </td>
              <td><p>Математика</p></td>
              <td><p>50</p></td>
              <td>
                <p className="tour-table__users">Серикбаева Жанель, Мантаева Дана, Каримова Амина, Амангалиев Алдияр, Хван Арсений, Хен Виктория, Шаяхметов Димаш, Есим Алдияр, Дырыло Илья</p>
              </td>
            </tr>
            <tr>
              <td>
                <span className="tour-table__name">Алхимики</span>
              </td>
              <td><p>Химия</p></td>
              <td><p>50</p></td>
              <td>
                <p className="tour-table__users">Петцке Анна, Ауелбекова Айсулу, Редикульцева Карина, Жакенова Аружан, Алиева Лэйла, Исахан Айсезим, Имашева Зарина, Бездольная Маргарита, Сейтенова Дильназ</p>
              </td>
            </tr>
            <tr>
              <td>
                <span className="tour-table__name">Gang of dragons</span>
              </td>
              <td><p>Биология</p></td>
              <td><p>50</p></td>
              <td>
                <p className="tour-table__users">Имашева Зарина, Шматко Юрий, Русланулы Дамир, Веселова Дарья, Закирова Гульфия, Давлетбаев Тимур, Жакупов Расул, Бисахметова Айгерим, Иванов Даниил, Галиаскаров Эльдар, Исабекова Аружан ,Будахина Маргарита</p>
              </td>
            </tr>
            <tr>
              <td>
                <span className="tour-table__name">Alpha</span>
              </td>
              <td><p>Физика</p></td>
              <td><p>50</p></td>
              <td>
                <p className="tour-table__users">Серикбаева Жанель, Мукарам Алихан, Кудайберген Данияр, Султанов Казбек, Реймер Софья, Каренкин Чингис, Корган Лейла</p>
              </td>
            </tr>
            <tr>
              <td>
                <span className="tour-table__name">Творцы</span>
                <span className="tour-table__name">Geo Squad</span>
              </td>
              <td><p>География</p></td>
              <td><p>25</p><p>25</p></td>
              <td>
                <p className="tour-table__users">Ерболатулы Ансар, Галиаскаров Эльдар, Каржас Асия, Давыдова Дарья</p>
                <p className="tour-table__users">Айдана Дулат, Ибраев Назар, Али Алиев, Жанатай Рауан</p>
              </td>
            </tr>
            <tr>
              <td>
                <span className="tour-table__name">Британская империя</span>
              </td>
              <td><p>Всемирная История</p></td>
              <td><p>50</p></td>
              <td>
                <p className="tour-table__users">Паров Ильяс Лугмарович, Дильназ Мурат, Леопольд Кристина Денсовна, Сипатов Даниэль Тимурович, Жиеналина Камила Бериковна, Баракбай Айым Адайкызы, Талгатова Дания Сериковна, Абишева Дильназ Айдаровна, Койшыбекова Жания Рахымжанова</p>
              </td>
            </tr>
            <tr>
              <td>
                <span className="tour-table__name">Big Brains</span>
                <span className="tour-table__name">Genius Clan</span>
              </td>
              <td><p>Английский</p></td>
              <td><p>50</p></td>
              <td>
                <p className="tour-table__users">Мурат Дильназ, Ибраев Назар, Меньшина Ясмина, Мординова Сабина, Сипатов Даниэль</p>
                <p className="tour-table__users">Скубилова Екатерина, Жалмуканова Арна, Полина Фролкина, Кунакпаев Алан, Жолдас Алуа</p>
              </td>
            </tr>
            <tr>
              <td>
                <span className="tour-table__name">Оранжевая команда</span>
              </td>
              <td><p>Информатика</p></td>
              <td><p>50</p></td>
              <td>
                <p className="tour-table__users">Адильбекулы Даниял, Әмір Айхат Асхат ұлы, Цой Роман Павлович</p>
              </td>
            </tr>
          </tbody>
        </table>
      </>
    );
  };
  const tabs = [
    {
      label: "Cентябрь",
      Component: TabSeptember,
    },
  ]
  const TabSeptemberKaz = () => {
    return (
      <>
        <table className="tour-table">
          <tbody>
            <tr>
              <th>
                <img src="assets/svg/tour.svg"></img>
              </th>
              <th>
                <img src="assets/svg/tour-name.svg"></img>
              </th>
              <th>
                <img src="assets/svg/tour-money.svg"></img>
              </th>
              <th>
                <img src="assets/svg/tour-user.svg"></img>
              </th>
            </tr>
            <tr>
              <td>
                <span className="tour-table__name">Алаш Орда </span>
              </td>
              <td><p>Қазақстан тарихы</p></td>
              <td><p>50</p></td>
              <td>
                <p className="tour-table__users">
                  Бектурханова Жанар, Надим Расул,
                  
                  Манарбек Мариям,
                  Оморбаев Фархат,
                  Полат Айжан,
                  Нұрақын Заңғар,
                  Бота Елубай,
                  Орунбасар Ұлдана,
                  Айым Мұса,
                  Жанайым,
                  Қадырқұл Қаламқас
                </p>
              </td>
            </tr>
            <tr>
              <td>
                <span className="tour-table__name">Kepler 22-b»</span>
              </td>
              <td><p>География</p></td>
              <td><p>50</p></td>
              <td>
                <p className="tour-table__users">
                  Жолдасбек Заңғар, Жолдыбаев Амантай, Маликова Анеля, Манарбек Мариям, Медеубай Әйгерім
                </p>
              </td>
            </tr>
            <tr>
              <td>
                <span className="tour-table__name">Болашақ</span>
              </td>
              <td><p>Мат.сауаттылық </p></td>
              <td><p>50</p></td>
              <td>
                <p className="tour-table__users">
                  Оразбек Улан, Досбол Арай, Ораз Айгерім, Надим Расул, Садық Жанайым
                </p>
              </td>
            </tr>
            <tr>
              <td>
                <span className="tour-table__name">Наполеон</span>
              </td>
              <td><p>Дүние тарихы</p></td>
              <td><p>50</p></td>
              <td>
                <p className="tour-table__users">Асель Нұрланқызы, Бестай Нұрай, Идаятова Айым, Ержанқызы Ділназ, Нури Амина, Ауезханова Муаззам</p>
              </td>
            </tr>
            <tr>
              <td>
                <span className="tour-table__name">EasyBio</span>
              </td>
              <td><p>Биология</p></td>
              <td><p>50</p></td>
              <td>
                <p className="tour-table__users">Алтынбек К., Анаркал К., Сайлаубаева Меруерт, Маликова Анеля, Бердалы Марал</p>
              </td>
            </tr>
            <tr>
              <td>
                <span className="tour-table__name">Зерттеушілер</span>
              </td>
              <td><p>Химия </p></td>
              <td><p>50</p></td>
              <td>
                <p className="tour-table__users">Бектурхонова Жанар, Нұрмак Аружан, Қайролла Алтынбек, Тұрды Аселхан, Бердалы Марал</p>
              </td>
            </tr>
            <tr>
              <td>
                <span className="tour-table__name">Жеңімпаздар</span>
              </td>
              <td><p>Математика</p></td>
              <td><p>50</p></td>
              <td>
                <p className="tour-table__users">Қадырқұл Қаламқас, Жолдыбаев Амантай Мухтарулы, Оразбек Улан Абзалулы, Надим Расул Акылбекович, Бурумбаева Дана, Садукас Асима</p>
              </td>
            </tr>
          </tbody>
        </table>
      </>
    );
  };
  const tabsKz = [
    {
      label: "қыркүйек",
      Component: TabSeptemberKaz,
    },
  ] 
  
  
  return (
    <div>
      <ul className="top-links">
        <li>
          <Link id="right" className="just-btn" to="/achievement">{t("achievment.Достижения", { ns: namespaces.common })}</Link>
        </li>
        <li>
          <Link id="left" className="just-btn active" to="/tournament">Турнир</Link>
        </li>
      </ul>
      <div className="tour-top">
        <div className="tour-top__left">
          <span>{t("tournament.Турнир_знаний", { ns: namespaces.common })} </span> {t("tournament.Показатель", { ns: namespaces.common })}.<br></br> 
          {t("tournament.Докажи", { ns: namespaces.common })}
        </div>
        <div className="tour-top__right">
          <img src="assets/level/tour.png"></img>
        </div>
      </div>
      <div className="tournament-table">
          <Tabs tabs={language == "ru" ? tabs : tabsKz} /> 
      </div>
    </div>
  );
};
