import axios from "axios";
import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { TeacherItem, TeacherItemSkeleton } from "src/components";
import { ITeacherItem } from "src/interface";
import { authHeader } from "src/utils/authHeader";

import "./TeacherPage.scss";

export const TeacherPage = () => {
  const params = useParams();
  document.title = "Преподаватели | EasyENT";
  const [teacher, setTeacher] = useState<ITeacherItem>();

  useEffect(() => {
    axios
      .get("/api/v1/teacher/" + params.id, { headers: authHeader() })
      .then((response) => setTeacher(response.data));
  }, []);  
  return (
    <div className="teachers-container">
      {teacher ? (
        <>
          <h1 className="title">Преподаватели!234</h1>
          <ul className="teachers-list">
            <TeacherItem key={teacher.id} teacher={teacher} />
          </ul>
        </>
      ) : (
        <TeacherItemSkeleton />
      )}

    </div>
  );
};
