import React, { FC } from "react";
import Skeleton from "react-loading-skeleton";
import { ITeacherItem } from "src/interface";


import "./TeacherItem.scss";

export const TeacherItem: FC<{ teacher: ITeacherItem }> = ({ teacher }) => {
  return (
    <li>
      <div className="teacher-item">
        <div className="image-wrapper">
          <img alt={teacher.image} src={teacher.image} />
        </div>
        <div className="teacher-info">
          <span className="teacher-info__subject">
            {teacher.name}
          </span>
          <p>
            <i className="fa-solid fa-book"></i>{teacher.subjects.map((subject, index) => (index == teacher.subjects.length - 1) ? subject.title : subject.title + ", ")} 
          </p>
          {/* <Link to={`/teacher_${teacher.id}`} className="btn btn-primary btn-flex">
            Подробнее <i className="fa-solid fa-angle-right"></i>
          </Link> */}
        </div>
      </div>
    </li>
  );
};

export const TeacherItemSkeleton = () => {
  return (
    <div className="teacher-item">
      <div className="image-wrapper">
        <Skeleton height={400} />
      </div>
      <div className="teacher-info">
        <Skeleton width={150} />
        <Skeleton height={23} width={280} />
        <Skeleton width={150} height={51} />
      </div>
    </div>
  );
};
