import React, { FC } from "react";
import { Link } from "react-router-dom";
import { ICourse,Order,PackageCourse } from "src/interface";
import "./CourseListItem.scss";

export const CourseListItem: FC<{ course: ICourse }> = ({ course }) => {
  const order:Order = {
    "positions": [{}]
  }
  const SetOrder:any = (course_id:number, course_name:string, packageCourse:PackageCourse) => {
    const localOrder = localStorage.getItem('order');
    if(typeof localOrder !== 'undefined' && localOrder !== null && order.positions.length > 0){        
      const newOrder:Order = JSON.parse(localStorage.getItem('order') || '{}');
      let generalStandartPrice = 0;
      let generalPremiumPrice = 0;
      if(newOrder['total_amount']){
        generalStandartPrice = newOrder['total_amount'];
        generalPremiumPrice = newOrder['total_amount'];
      }
      const found = newOrder.positions.some(item => item.course_id === course_id);   
      if(found == false){                           
        const addedCourselength:number = newOrder['positions'].length;
        let saleCourseStandart = 0;
        let saleCoursePremium = 0;
        if(addedCourselength > 4){
          saleCourseStandart = 40;
          saleCoursePremium = 20;
        }
        else{
          saleCourseStandart = addedCourselength * 10;
          saleCoursePremium = addedCourselength * 5;
        }          
        const coursePriceStandart:number = 6900 - (saleCourseStandart * 6900) / 100;
        const coursePricePremium:number = 12000 - (saleCoursePremium * 12000) / 100;
        generalStandartPrice += coursePriceStandart;
        generalPremiumPrice += coursePricePremium;
        if(packageCourse == PackageCourse.standart){
          newOrder['package'] = PackageCourse.standart;
          newOrder['total_amount'] = generalStandartPrice;
        } 
        else{
          newOrder['package'] = PackageCourse.premium;
          newOrder['total_amount'] = generalPremiumPrice;
        }                     
        newOrder['positions'].push({
          "course_id": course_id,
          "name": course_name,
          "priceStandart": coursePriceStandart,
          "pricePremium": coursePricePremium
        });
        localStorage.setItem('order', JSON.stringify(newOrder));
      }
      else{
        console.log('этот курс уже добавлен');
      }
    }
    else {
      if(packageCourse == PackageCourse.standart){
        order['package'] = PackageCourse.standart;
        order['total_amount'] = 6900;
      }
      else{
        order['total_amount'] = 12000;
      }
      order['is_full'] = false;
      order['start_date'] = "2022-07-10"; 
      order['end_date'] =  "2022-08-10"                
      order['positions'][0]['course_id'] = course_id;
      order['positions'][0]['name'] = course_name;  
      order['positions'][0]['priceStandart'] = 6900;  
      order['positions'][0]['pricePremium'] = 12000;  
      localStorage.setItem('order', JSON.stringify(order)); 
    }   
  }
  return (
    <li className="course-list-item">
      <div className="course-list-item__wr">
        <div className="course-list-item__img">
          <img src={course.photo_url} />
        </div>
        <span className="course-list-item__name">{course.name}</span>
        <div className="course-details">
          <p>
            <i className="fa-solid fa-clock"></i> Длительность:{" "}
            <span>{course.duration_month} месяцев</span>
          </p>
          <p>
            <i className="fa-solid fa-book"></i> Кол-во уроков:{" "}
            <span>{course.lesson_count}</span>
          </p>
        </div>
        <div className="course-list__bot">
          <Link to={`view_${course.id}`} className="btn-work">
            Подробнее <i className="fa-solid fa-angle-right"></i>
          </Link>
          <Link to="/buy" className="btn-work" onClick={() => SetOrder(course.id, course.name, PackageCourse.standart)}>
            Купить курс <i className="fa-solid fa-angle-right"></i>
          </Link>
        </div>
      </div>  
    </li>
  );
};
