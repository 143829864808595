import axios from "axios";
import React, { useEffect, useState } from "react";
import { TeacherHomeworkByCourse } from "src/interface";
import { Link } from "react-router-dom";

import "./TeacherHomeworkSubjectPage.scss";
import { authHeader } from "src/utils/authHeader";

export const TeacherHomeworkSubjectPage = () => {
  document.title = "ДЗ | EasyENT";
  const [allHomeworks, setAllHomeworks] = useState<TeacherHomeworkByCourse[]>([]);

  useEffect(() => {
    axios
      .get("/api/v1/homework/by_courses/217",{ headers: authHeader()})
      .then((response) => {
          setAllHomeworks(response.data)
        }
      );
  }, []);
 

  return (allHomeworks) ? (
    <div className="my-course">
      <ul className="breadcrumbs">
        <li>Домашнее задание</li>
      </ul>
      <h1 className="title">Проверка дз внутр</h1>
      <ul className="hw_list">
        <li>
          <div className="hw-row hw-row--top">
            <div className="hw-row__item hw-row__number">
               № 
            </div>
            <div className="hw-row__item hw-row__one">
               Тема
            </div>
            <div className="hw-row__item hw-row__two">
              Ученик
            </div>
            <div className="hw-row__item hw-row__five">
              Проверка
            </div>
          </div>
        </li> 
        {allHomeworks.map((homework, i)  => (
          <li key={i}>
            <div className="hw-row">
              <div className="hw-row__item hw-row__number">
                {homework.lesson_id} урок
              </div>
              <div className="hw-row__item hw-row__one">
                {homework.lesson_name} 
              </div>
              <div className="hw-row__item hw-row__two">
                {homework.student_name} 
              </div>
              <div className="hw-row__item hw-row__five">
              <Link className="btn-work" to={`${homework.lesson_id}&${homework.student_id}`}>Проверить <i className="fa-solid fa-angle-right"></i></Link> 
              </div>
            </div>
          </li>
          ))
        }
      </ul>  
    </div>
  ) : 
  ( 
    <div>Пусто</div>
  )
};
