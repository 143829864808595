import axios from "axios";
import React, {  useState } from "react";
import { authHeader } from "src/utils/authHeader";
import { Link } from "react-router-dom";
import Select from 'react-select';
export const AdminTestAdd = () => {
  document.title = "Тесты | EasyENT";
  // const params = useParams();
  const [languageOption,setLanguageOption] = useState<any>({ value: 'ru', label: 'Русский язык' });
  const [newTest, setNewTest] = useState<any>({
    subject_id:1,
    lang:"ru",
    write_date: "2024-01-22T11:36:21.623Z",
  });
  console.log(newTest);
  const onChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const element = e.target as HTMLInputElement;
    const value = element.value;
    // const name = element.name;
    setNewTest((s:any) => ({ ...s, ['write_date']: value }));
    console.log(newTest);
  };
  const [subjectValue, setSubjectValue] = useState<any>({ value: 1, label: 'История Казахстана' });
  const onSubmit = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    axios
      .post("/api/v1/tests/tests/", newTest, { headers: authHeader() })
      .then((response) => {
        if (response.status == 200) {
          window.location.reload();
          alert('Тест добавлен')
        }
      })
      .catch((error) => {
        alert(error.response.data);
      });
  }
  const languageOptions:any = [
    { value: 'ru', label: 'Русский язык' },
    { value: 'kz', label: 'Казахский язык' },
  ];
  const kzsubjectOptions:any = [
    { value: 232, label: 'Қазақстан тарихы' },
    { value: 265, label: 'Математикалық сауаттылық' },
    { value: 317, label: 'Математика' },
    { value: 336, label: 'Химия' },
    { value: 377, label: 'Биология' },
    { value: 399, label: 'География' },
    { value: 422, label: 'Дүниежүзі тарихы' },
  ];    
  const subjectOptions:any = [
    { value: 1, label: 'История Казахстана' },
    { value: 5, label: 'Мат.Грамотность' },
    { value: 10, label: 'Математика' },
    { value: 23, label: 'Физика' },
    { value: 29, label: 'Химия' },
    { value: 48, label: 'Биология' },
    { value: 91, label: 'География' },
    { value: 100, label: 'Всемирная История' },
    { value: 110, label: 'Английский язык' },
    { value: 121, label: 'Информатика' },
  ];
  return (
    <div className="my-course">
      <ul className="breadcrumbs">
        <li><Link to="/admin/courses">Мои тетсы</Link></li>
        <li><span><i className="fa-solid fa-arrow-right"></i></span></li>
        <li><span>Добавление теста</span></li>
      </ul>
      <h1 className="title">Добавление теста</h1>
      <form onSubmit={onSubmit} className="add-dz box">
        <div className="form-row form-row--main">
          <label><b>Язык теста:</b></label>
          <Select 
            defaultValue={languageOption}
            options={languageOptions}
            onChange={(option: any) => {
              setLanguageOption(option)
            }}  
            name="display_name"
          />
        </div>
        <div className="form-row form-row--main">
          <label><b>Предмет:</b></label>
          <Select 
            defaultValue={subjectValue}
            options={languageOption.value == 'ru' ? subjectOptions : kzsubjectOptions}
            onChange={(option: any) => {
            if(  option ) setNewTest((item:any) => ({...item,
                  subject_id: option.value
                })
              ) ;
                setSubjectValue(option);
                }
            }  
            name="display_name"
          />
        </div>
        <div className="form-row form-row--main">
          <label><b>Дата:</b></label>
          <input
              required
              name="start_date"
              type="datetime-local"
              onChange={onChange}
            />
        </div>
        
        <button className="btn btn-primary" type="submit">Отправить</button>
      </form>
    </div>
  );
};
