import axios from "axios";
import React, { useEffect, useState } from "react";
import { useParams, Link } from "react-router-dom";
import { MyCourseItem } from "src/components";
import { ICourse,  ILesson, User_feeds } from "src/interface";
import { authHeader } from "src/utils/authHeader";
import { MyLessonItem } from "src/components";
import { Tabs } from "react-simple-tabs-component";
import { useTranslation } from "react-i18next";
import { namespaces } from "src/i18n/i18n.constants";
import { useAppSelector } from "src/hooks/redux";
import "./ViewCoursePage.scss";

export const ViewCoursePage = () => {
  const params = useParams();
  const [, setLanguage] = useState<any>("");
  useEffect(() => {
    const mylanguage = localStorage.getItem('language');
    if(typeof mylanguage !== 'undefined' && mylanguage !== null){
      setLanguage(JSON.parse(mylanguage));
    }
  }, []);
  const [course, setCourse] = useState<ICourse>();
  const [lessons, setLessons] = useState<ILesson[]>([]);
  const [octLessons, setOctLessons] = useState<ILesson[]>([]);
  const [novLessons, setNovLessons] = useState<ILesson[]>([]);
  const [decLessons, setDecLessons] = useState<ILesson[]>([]);
  const [lessonCompleted, setLessonCompleted] = useState<any>([]);
  const [User_feeds, setUser_feeds] = useState<User_feeds[]>([]);
  const user = useAppSelector((store) => store.user.data);
  const { t } = useTranslation(namespaces.pages.hello);
  function getCompletedLessons() {
    axios
      .get(`api/v1/courses/${params?.id}/lesson_completed_count`, {
        headers: authHeader(),
      })
      .then((res: any) => setLessonCompleted(res?.data));
  }

  useEffect(() => {
    axios
      .get("/api/v1/courses/" + params.id, { headers: authHeader() })
      .then((response) => {
        setCourse(response.data);
    });
  }, []);  

  useEffect(() => {
    axios
      .get("/api/v1/courses/" + params.id + "/lessons/?from_date=2022-09-01&to_date=2024-10-11&skip=0&limit=100", {
        headers: authHeader(),
      })
      .then((response) => {
        setLessons(response.data);
      });
    axios
    .get("/api/v1/courses/" + params.id + "/lessons/?from_date=2023-10-11&to_date=2023-11-11&skip=0&limit=30", {
      headers: authHeader(),
    })
    .then((response) => {
      setOctLessons(response.data);
    }); 
    
    axios
    .get("/api/v1/courses/" + params.id + "/lessons/?from_date=2023-11-11&to_date=2023-12-11&skip=0&limit=30", {
      headers: authHeader(),
    })
    .then((response) => {
      setNovLessons(response.data);
    }); 

    axios
    .get("/api/v1/courses/" + params.id + "/lessons/?from_date=2023-12-11&to_date=2024-01-11&skip=0&limit=30", {
      headers: authHeader(),
    })
    .then((response) => {
      setDecLessons(response.data);
    }); 

    axios
      .get(
        "/api/v1/user_feed/?skip=0&user_id=" +
          user.id +
          "&course_id=" +
          params.id,
        { headers: authHeader() }
      )
      .then((response) => {
        setUser_feeds(response.data);
      });
    getCompletedLessons();
  }, []);

  
  const TabSeptember = () => {
    return (
      <>
        {User_feeds.filter((e) => e.start_date === "2023-09-11").length > 0
          ? lessons.map((lesson, index) =>
              lesson.start_date ? (
                  <MyLessonItem
                    lesson={lesson}
                    index={index}
                    without_penalty={course ? course.without_penalty : false}
                    key={lesson.id}
                    completed={lessonCompleted}
                  />
              ) : (
                ""
              )
            )
          : "Доступов за сентябрь нету"}
      </>
    );
  };

  const TabOсtober = () => {
    return (
      <>
        {User_feeds.filter((e) => e.start_date === "2023-10-11").length > 0
          ? octLessons.map((lesson, index) =>
            lesson.start_date ? (
              <MyLessonItem
                lesson={lesson}
                index={8 + index}
                without_penalty={course ? course.without_penalty : false}
                key={lesson.id}
                completed={lessonCompleted}
              />
          ) : (
            ""
          )
        )
      : "Доступов за октябрь нету"}
      </>
    );
  };
  const TabNovember = () => {
    return (
      <>
        {User_feeds.filter((e) => e.start_date === "2023-11-11").length > 0
          ? novLessons.map((lesson, index) =>
            lesson.start_date ? (
              <MyLessonItem
                lesson={lesson}
                index={16 + index}
                without_penalty={course ? course.without_penalty : false}
                key={lesson.id}
                completed={lessonCompleted}
              />
          ) : (
            ""
          )
        )
      : "Доступов за ноябрь нету"}
      </>
    );
  };
  const TabDecember = () => {
    return (
      <>
        {User_feeds.filter((e) => e.start_date === "2023-11-11").length > 0
          ? decLessons.map((lesson, index) =>
            lesson.start_date ? (
              <MyLessonItem
                lesson={lesson}
                index={16 + index}
                without_penalty={course ? course.without_penalty : false}
                key={lesson.id}
                completed={lessonCompleted}
              />
          ) : (
            ""
          )
        )
      : "Доступов за декабрь нету"}
      </>
    );
  };
  const TabJanuary = () => {
    const [JanLesson, setJanLesson] = useState<ILesson[]>([]);
    useEffect(() => {
      axios
      .get("/api/v1/courses/" + params.id + "/lessons/?from_date=2024-01-11&to_date=2024-02-11&skip=0&limit=30", {
        headers: authHeader(),
      })
      .then((response) => {
        setJanLesson(response.data);
      }); 
    }, []);
    return (
      <>
      {User_feeds.filter((e) => e.start_date === "2024-01-11").length > 0
        ? JanLesson.map((lesson, index) =>
          lesson.start_date ? (
            <MyLessonItem
              lesson={lesson}
              index={16 + index}
              key={lesson.id}
              without_penalty={course ? course.without_penalty : false}
              completed={lessonCompleted}
            />
        ) : (
          ""
        )
      )
    : "Доступов за январь нету"}
    </>
    );
  };
  const TabFebruary = () => {
    const [FebLesson, setFebLesson] = useState<ILesson[]>([]);
    useEffect(() => {
      axios
      .get("/api/v1/courses/" + params.id + "/lessons/?from_date=2024-02-11&to_date=2024-03-11&skip=0&limit=30", {
        headers: authHeader(),
      })
      .then((response) => {
        setFebLesson(response.data);
      }); 
    }, []);
    return (
      <>
      {User_feeds.filter((e) => e.start_date === "2024-02-11").length > 0
        ? FebLesson.map((lesson, index) =>
          lesson.start_date ? (
            <MyLessonItem
              lesson={lesson}
              index={24 + index}
              key={lesson.id}
              without_penalty={course ? course.without_penalty : false}
              completed={lessonCompleted}
            />
        ) : (
          ""
        )
      )
    : "Доступов за февраль нету"}
    </>
    );
  };
  const TabMarch = () => {
    const [MarchLesson, setMarchLesson] = useState<ILesson[]>([]);
    useEffect(() => {
      axios
      .get("/api/v1/courses/" + params.id + "/lessons/?from_date=2024-03-11&to_date=2024-04-11&skip=0&limit=30", {
        headers: authHeader(),
      })
      .then((response) => {
        setMarchLesson(response.data);
      }); 
    }, []);
    return (
      <>
      {User_feeds.filter((e) => e.start_date === "2024-03-11").length > 0
        ? MarchLesson.map((lesson, index) =>
          lesson.start_date ? (
            <MyLessonItem
              lesson={lesson}
              index={24 + index}
              key={lesson.id}
              without_penalty={course ? course.without_penalty : false}
              completed={lessonCompleted}
            />
        ) : (
          ""
        )
      )
    : "Доступов за март нету"}
    </>
    );
  };
  const TabApril = () => {
    const [aprilLesson, setAprilLesson] = useState<ILesson[]>([]);
    useEffect(() => {
      axios
      .get("/api/v1/courses/" + params.id + "/lessons/?from_date=2024-04-11&to_date=2024-05-11&skip=0&limit=30", {
        headers: authHeader(),
      })
      .then((response) => {
        setAprilLesson(response.data);
      }); 
    }, []);
    return (
      <>
      {User_feeds.filter((e) => e.start_date === "2024-04-11").length > 0
        ? aprilLesson.map((lesson, index) =>
          lesson.start_date ? (
            <MyLessonItem
              lesson={lesson}
              index={index}
              key={lesson.id}
              without_penalty={course ? course.without_penalty : false}
              completed={lessonCompleted}
            />
        ) : (
          ""
        )
      )
    : "Доступов за Апрель нету"}
    </>
    );
  };
  const Tab2november = () => {
    const [nov2Lessons, setNov2Lessons] = useState<ILesson[]>([]);
    useEffect(() => {
      axios
      .get("/api/v1/courses/" + params.id + "/lessons/?from_date=2023-11-13&to_date=2023-12-13&skip=0&limit=30", {
        headers: authHeader(),
      })
      .then((response) => {
        setNov2Lessons(response.data);
      }); 
    }, []);  
    return (
      <>
        {User_feeds.filter((e) => e.start_date === "2023-11-13").length > 0
          ? nov2Lessons.map((lesson, index) =>
            lesson.start_date ? (
              <MyLessonItem
                lesson={lesson}
                index={1 + index}
                key={lesson.id}
                without_penalty={course ? course.without_penalty : false}
                completed={lessonCompleted}
              />
          ) : (
            ""
          )
        )
      : "Доступов за ноябрь нету"}
      </>
    );
  };
  const Tab2december = () => {
    const [dec2Lessons, setDec2Lessons] = useState<ILesson[]>([]);
    useEffect(() => {
      axios
      .get("/api/v1/courses/" + params.id + "/lessons/?from_date=2023-12-13&to_date=2024-01-13&skip=0&limit=30", {
        headers: authHeader(),
      })
      .then((response) => {
        setDec2Lessons(response.data);
      }); 
    }, []);
    return (
      <>
        {User_feeds.filter((e) => e.start_date === "2023-12-13").length > 0
          ? dec2Lessons.map((lesson, index) =>
            lesson.start_date ? (
              <MyLessonItem
                lesson={lesson}
                index={1 + index}
                key={lesson.id}
                without_penalty={course ? course.without_penalty : false}
                completed={lessonCompleted}
              />
          ) : (
            ""
          )
        )
      : "Доступов за декабрь нету"}
      </>
    );
  };
  const Tab2january = () => {
    const [Jan2Lesson, setJan2Lesson] = useState<ILesson[]>([]);
    useEffect(() => {
      axios
      .get("/api/v1/courses/" + params.id + "/lessons/?from_date=2024-01-13&to_date=2024-02-13&skip=0&limit=30", {
        headers: authHeader(),
      })
      .then((response) => {
        setJan2Lesson(response.data);
      }); 
    }, []);
    return (
      <>
        {User_feeds.filter((e) => e.start_date === "2024-01-13").length > 0
          ? Jan2Lesson.map((lesson, index) =>
            lesson.start_date ? (
              <MyLessonItem
                lesson={lesson}
                index={1 + index}
                key={lesson.id}
                without_penalty={course ? course.without_penalty : false}
                completed={lessonCompleted}
              />
          ) : (
            ""
          )
        )
      : "Доступов за январь нету"}
      </>
    );
  };
  const Tab2february = () => {
    const [Feb2Lessons, setFeb2Lessons] = useState<ILesson[]>([]);
    useEffect(() => {
      axios
      .get("/api/v1/courses/" + params.id + "/lessons/?from_date=2024-02-13&to_date=2024-03-13&skip=0&limit=30", {
        headers: authHeader(),
      })
      .then((response) => {
        setFeb2Lessons(response.data);
      }); 
    }, []);
    return (
      <>
        {User_feeds.filter((e) => e.start_date === "2024-02-13").length > 0
          ? Feb2Lessons.map((lesson, index) =>
            lesson.start_date ? (
              <MyLessonItem
                lesson={lesson}
                index={1 + index}
                key={lesson.id}
                without_penalty={course ? course.without_penalty : false}
                completed={lessonCompleted}
              />
          ) : (
            ""
          )
        )
      : "Доступов за февраль нету"}
      </>
    );
  };
  const Tab2march = () => {
    const [March2Lessons, setMarch2Lessons] = useState<ILesson[]>([]);
    useEffect(() => {
      axios
      .get("/api/v1/courses/" + params.id + "/lessons/?from_date=2024-03-13&to_date=2024-04-13&skip=0&limit=30", {
        headers: authHeader(),
      })
      .then((response) => {
        setMarch2Lessons(response.data);
      }); 
    }, []);
    return (
      <>
        {User_feeds.filter((e) => e.start_date === "2024-03-13").length > 0
          ? March2Lessons.map((lesson, index) =>
            lesson.start_date ? (
              <MyLessonItem
                lesson={lesson}
                index={1 + index}
                key={lesson.id}
                without_penalty={course ? course.without_penalty : false}
                completed={lessonCompleted}
              />
          ) : (
            ""
          )
        )
      : "Доступов за март нету"}
      </>
    );
  };
  const Tab2april = () => {
    const [April2Lessons, setApril2Lessons] = useState<ILesson[]>([]);
    useEffect(() => {
      axios
      .get("/api/v1/courses/" + params.id + "/lessons/?from_date=2024-04-13&to_date=2024-05-13&skip=0&limit=30", {
        headers: authHeader(),
      })
      .then((response) => {
        setApril2Lessons(response.data);
      }); 
    }, []);
    return (
      <>
        {User_feeds.filter((e) => e.start_date === "2024-04-13").length > 0
          ? April2Lessons.map((lesson, index) =>
            lesson.start_date ? (
              <MyLessonItem
                lesson={lesson}
                index={1 + index}
                key={lesson.id}
                without_penalty={course ? course.without_penalty : false}
                completed={lessonCompleted}
              />
          ) : (
            ""
          )
        )
      : "Доступов за Апрель нету"}
      </>
    );
  };
  const Tab3january = () => {
    const [Jan3Lesson, setJan3Lesson] = useState<ILesson[]>([]);
    useEffect(() => {
      axios
      .get("/api/v1/courses/" + params.id + "/lessons/?from_date=2024-01-15&to_date=2024-02-15&skip=0&limit=30", {
        headers: authHeader(),
      })
      .then((response) => {
        setJan3Lesson(response.data);
      }); 
    }, []);
    return (
      <>
        {User_feeds.filter((e) => e.start_date === "2024-01-15").length > 0
          ? Jan3Lesson.map((lesson, index) =>
            lesson.start_date ? (
              <MyLessonItem
                lesson={lesson}
                index={1 + index}
                key={lesson.id}
                without_penalty={course ? course.without_penalty : false}
                completed={lessonCompleted}
              />
          ) : (
            ""
          )
        )
      : "Доступов за январь нету"}
      </>
    );
  };

  const Tab3february = () => {
    const [Feb3Lessons, setFeb3Lessons] = useState<ILesson[]>([]);
    useEffect(() => {
      axios
      .get("/api/v1/courses/" + params.id + "/lessons/?from_date=2024-02-15&to_date=2024-03-15&skip=0&limit=50", {
        headers: authHeader(),
      })
      .then((response) => {
        setFeb3Lessons(response.data);
      }); 
    }, []);
    return (
      <>
        {User_feeds.filter((e) => e.start_date === "2024-02-15").length > 0
          ? Feb3Lessons.map((lesson, index) =>
            lesson.start_date ? (
              <MyLessonItem
                lesson={lesson}
                index={1 + index}
                key={lesson.id}
                without_penalty={course ? course.without_penalty : false}
                completed={lessonCompleted}
              />
          ) : (
            ""
          )
        )
      : "Доступов за февраль нету"}
      </>
    );
  };
  const Tab3March = () => {
    const [March3Lessons, setMarch3Lessons] = useState<ILesson[]>([]);
    useEffect(() => {
      axios
      .get("/api/v1/courses/" + params.id + "/lessons/?from_date=2024-03-15&to_date=2024-04-15&skip=0&limit=30", {
        headers: authHeader(),
      })
      .then((response) => {
        setMarch3Lessons(response.data);
      }); 
    }, []);
    return (
      <>
        {User_feeds.filter((e) => e.start_date === "2024-03-15").length > 0
          ? March3Lessons.map((lesson, index) =>
            lesson.start_date ? (
              <MyLessonItem
                lesson={lesson}
                index={1 + index}
                key={lesson.id}
                without_penalty={course ? course.without_penalty : false}
                completed={lessonCompleted}
              />
          ) : (
            ""
          )
        )
      : "Доступов за март нету"}
      </>
    );
  };
  const Tab3April = () => {
    const [April3Lessons, setApril3Lessons] = useState<ILesson[]>([]);
    useEffect(() => {
      axios
      .get("/api/v1/courses/" + params.id + "/lessons/?from_date=2024-04-15&to_date=2024-05-15&skip=0&limit=30", {
        headers: authHeader(),
      })
      .then((response) => {
        setApril3Lessons(response.data);
      }); 
    }, []);
    return (
      <>
        {User_feeds.filter((e) => e.start_date === "2024-04-15").length > 0
          ? April3Lessons.map((lesson, index) =>
            lesson.start_date ? (
              <MyLessonItem
                lesson={lesson}
                index={1 + index}
                key={lesson.id}
                without_penalty={course ? course.without_penalty : false}
                completed={lessonCompleted}
              />
          ) : (
            ""
          )
        )
      : "Доступов за Апрель нету"}
      </>
    );
  };
  const Tabfree = () => {
    return (
      <>
        {lessons.map.length > 0
          ? lessons.map((lesson, index) =>
              lesson.start_date ? (
                  <MyLessonItem
                    lesson={lesson}
                    index={index}
                    key={lesson.id}
                    without_penalty={course ? course.without_penalty : false}
                    completed={lessonCompleted}
                  />
              ) : (
                ""
              )
            )
          : "Уроки еще не загружены"}
      </>
    );
  };
  const tabs1 = [
    {
      label: "11 Cен - 10 Окт",
      Component: TabSeptember,
    },
    {
      label: "11 Окт - 10 Нояб",
      Component: TabOсtober,
    },
    {
      label: "11 Нояб - 10 Дек",
      Component: TabNovember,
    },
    {
      label: "11 Дек - 10 Янв",
      Component: TabDecember,
    },
    {
      label: "11 Янв - 10 Февр",
      Component: TabJanuary,
    },
    {
      label: "11 Февр - 10 Март",
      Component: TabFebruary,
    },
    {
      label: "11 Март - 10 Апр",
      Component: TabMarch,
    },
    {
      label: "11 Апр - 10 Мая",
      Component: TabApril,
    },
  ];
  const tabs2 = [
    {
      label: "13 ноября - 12 декабря",
      Component: Tab2november,
    },
    {
      label: "13 декабря - 12 января",
      Component: Tab2december,
    },
    {
      label: "13 января - 12 февраля",
      Component: Tab2january,
    },
    {
      label: "13 февраля - 12 марта",
      Component: Tab2february,
    },
    {
      label: "13 марта - 12 апреля",
      Component: Tab2march,
    },
    {
      label: "13 апреля - 12 мая",
      Component: Tab2april,
    }
  ]
  const tabs3 = [
    {
      label: "15 января - 14 февраля",
      Component: Tab3january,
    },
    {
      label: "15 февраля - 14 марта",
      Component: Tab3february,
    },
    {
      label: "15 марта - 14 апреля",
      Component: Tab3March,
    },
    {
      label: "15 апреля - 14 мая",
      Component: Tab3April,
    }
  ]  
  const emptytabs = [
    {
      label: "Уроки",
      Component: Tabfree,
    },
  ];

  return (
    <div className="view-course">
      <ul className="breadcrumbs">
        <li>
          <Link to="/my-courses">{t("navbar.Мои_курсы", { ns: namespaces.common })}</Link>
        </li>
        <li>
          <span>
            <i className="fa-solid fa-arrow-right"></i>
          </span>
        </li>
        <li>
          <span>{course ? course.name : ""}</span>
        </li>
      </ul>
      {course ? (
        <MyCourseItem key={params.id} showLessons={true} course={course} />
      ) : (
        ""
      )}
      <div className="last-lesson">
        {course ? (
            <>
              <Tabs tabs={course.price === 1 ? 
                tabs1 : 
                  course.price === 2 ? tabs2 : 
                  course.price === 3 ? tabs3 :
                  emptytabs
                } /> 
            </>
        ) : (
          <>
            <Tabs tabs={emptytabs} /> 
          </>
        )}
      </div>
    </div>
  );
};
