import React, { useEffect, useRef } from "react";
import { useNavigate } from "react-router-dom";
import { Link } from "react-router-dom";
import { Header, Footer } from "src/components";
import { useAppDispatch, useAppSelector } from "src/hooks/redux";
import { forgetpassword } from "../../service/asyncThunk";



export const ForgetPassword = () => {
  document.title = "Забыли пароль | EasyENT";

  const navigate = useNavigate();
  const dispatch = useAppDispatch();


  const isLoggedIn = useAppSelector((store) => store.user.loggedIn);
  const InputEmail = useRef<HTMLInputElement>(null);


  const onSubmit = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    dispatch(
      forgetpassword({
        email: InputEmail?.current?.value,
      })
    ).then((res: any) => {
      if (!res?.error?.message) {
        navigate("/forgetreturn");
      }
    });
  };

  useEffect(() => {
    if (isLoggedIn) {
      navigate("/calendar");
    }
  }, []);

  return (
    <div className="app-container">
      <Header isEmpty={true} />
      <div className="content-wrapper">
        <div className="login-container">
          <img src="assets/login.png" />
          <form className="login-form" onSubmit={onSubmit}>
            <h1>Восстановление пароля</h1>
            <p className="login-form__forget">Укажите, куда отправить инструкции для восстановления пароля.</p>
            <input
              type="email"
              name="email"
              className="login-form__input"
              ref={InputEmail}
              defaultValue=""
              required
              placeholder="Электронная почта"
            />              
            <button className="btn btn-primary btn-flex" type="submit">
              Отправить <i className="fa-solid fa-right-to-bracket"></i>
            </button>
            <span className="register">
              Еще нет профиля? <Link to="/register">Зарегистрируйтесь</Link>
            </span>
          </form>
        </div>
      </div>
      <Footer />
    </div>
    
  );
};
export default ForgetPassword;