import { namespaces } from "./i18n.constants";

export const ru = {
  [namespaces.common]: {
    login: {
      Вход_в_личный_кабинет: "Вход в личный кабинет",
      Войти: "Войти",
      Забыли_пароль: "Забыл(а) пароль",
      Мои_подписки: "Мои подписки",
      Еще_нет_профиля: "Еще нет профиля?",
      Зарегистрируйтесь: "Зарегистрируйтесь",
    },
    header: {
      Мой_профиль: "Мой профиль",
      Мои_транзакции: "Мои транзакции",
      Мои_подписки: "Мои подписки",
    },
    navbar: {
      Мой_ребенок: "Мой ребенок",
      Мой_календарь: "Мой календарь",
      Мои_курсы: "Мои курсы",
      Интернет_магазин: "Интернет магазин",
      Достижения: "Достижения",
      Преподаватели: "Преподаватели",
      Мои_оплаты: "Мои оплаты",
      Тесты_ЕНТ: "Тесты ЕНТ",
      Купить_курсы: "Купить курсы",
      Выход: "Выход",
    },
    subscriptionPage: {
      Номер_заказа: "Номер заказа",
      Мои_курсы: "Мои курсы",
      Стоимость: "Стоимость",
      Не_оплачено: "Не оплачено",
      Оплатить_через_Kaspi: "Оплатить через Kaspi",
      Если_оплата_не_выходит: "Если оплата не выходит при нажатии на кнопку оплатить",
      Оплата_инструкция1: "Войдите в раздел Мои платежи, в kaspi приложении",
      Оплата_инструкция2: "Введите в поиске EasyENT",
      Оплата_инструкция3: "Перейдите в раздел EasyENT, образование",
      Оплата_инструкция4: "Введите номер заказа:",
      Оплата_инструкция5: "Оплатите заказ",
      Если_цена_неверная: "Если цена неверная",
      Напишите_пожалуйста: "Напишите пожалуйста на номер: +7 707 510 0402 (Марьям)"
      
    },
    achievment: {
      Достижения: "Достижения",
      Мой_уровень: "Мой_уровень",
      Как_это_работает: "Как_это_работает?",
      Выполняй_домашнее: "Выполняй домашнее задание на платформе, cдавай зачеты и повышай свой уровень",
      За_что_начисляетcя_studycoins: "За что начисляетcя studycoins:",
      Выполнение_дз: "Выполнение дз (макс)",
      Сдача_зачета: "Сдача зачета",
      Победа_в_турнире: "Победа_в_турнире (макс)",
      Решение_теста_по_ЕНТ: "Решение теста по ЕНТ (макс)",
      Лучший_ученик_который_наберет: "Лучший ученик, который наберет наибольшее количество StudyCoins и сдаст ЕНТ на 120+ Получит IPhone 15",
    },
    tournament: {
      Турнир_знаний: "Турнир знаний -",
      Показатель: "показатель вашего уровня.",
      Докажи: "Докажи, что вы лучшая команда!",
    },
    inner_course: {
      Достижения: "Достижения",
      Система_жизни: "Система жизни",
      Телеграм_чат_с_кураторами: "Телеграм чат с кураторами",
    },
    lesson: {
      Урок: "Урок",
      Посмотреть_запись: "Посмотреть запись",
      Домашнее_задание: "Домашнее задание",
      Открыть_конспект : "Открыть_конспект",
      Выполнено : "выполнено",
    },
    general: {
      Подробнее_о_курсе: "Подробнее о курсе",
    }  
  },
  [namespaces.pages.hello]: {
    welcome: "Русский",
  },
};

export const kz = {
  [namespaces.common]: {
    login: {
      Вход_в_личный_кабинет: "Жеке кабинетке кіру",
      Войти: "Кіру",
      Забыли_пароль: "Құпия сөзді ұмыттыңыз ба?",
      Мои_подписки: "Мои подписки",
      Еще_нет_профиля: "Әлі профиліңіз жоқ па?",
      Зарегистрируйтесь: "Регистрациядан өтіңіз",
    },
    header: {
      Мой_профиль: "Менің профилім",
      Мои_транзакции: "Менің транзакцияларым",
      Мои_подписки: "Менің жазылымдарым",
    },
    navbar: {
      Мой_календарь: "Күнтізбе",
      Мои_курсы: "Менің курстарым",
      Интернет_магазин: "Интернет-дүкені",
      Достижения: "Жетістіктер",
      Преподаватели: "Мұғалімдер",
      Мои_оплаты: "Менің төлемдерім",
      Тесты_ЕНТ: "Ұбт тестері",
      Купить_курсы: "Курсты сатып алу",
      Выход: "Шығу",
    },
    subscriptionPage: {
      Номер_заказа: "Тапсырыс нөмірі",
      Мои_курсы: "Менің курстарым",
      Стоимость: "Құны",
      Не_оплачено: "Төленбеген",
      Оплатить_через_Kaspi: "Kaspi арқылы төлеу",
      Если_оплата_не_выходит: "Егер Төлеу батырмасын басқан кезде төлем шықпаса",
      Оплата_инструкция1: "Kaspi қосымшасында 'Мои Платежи' бөліміне кіріңіз",
      Оплата_инструкция2: "Іздеуде EasyENT деп теріңіз",
      Оплата_инструкция3: "EasyENT, білім (образование) бөліміне өтіңіз",
      Оплата_инструкция4: "Тапсырыс нөмірін енгізіңіз:",
      Оплата_инструкция5: "Тапсырысты төлеңіз",
      Если_цена_неверная: "Егер төлем құны дұрыс көрсетілмесе",
      Напишите_пожалуйста: "Келесі нөмірге жазыңыз: +7 708 491 06 29 (Инабат)"
    },
    achievment: {
      Достижения: "Жетістіктер",
      Мой_уровень: "менің деңгейім",
      Как_это_работает: "Бұл қалай жұмыс істейді?",
      Выполняй_домашнее: "Платформада үй тапсырмасын орындап, сынақтарды үздік тапсыру арқылы деңгейіңізді көтеріңіз",
      За_что_начисляетcя_studycoins: "Сoins не үшін беріледі",
      Выполнение_дз: "Үй жұмысын орындау: (макс)",
      Сдача_зачета: "Зачета тапсыру (макс)",
      Победа_в_турнире: "Турнирдегі жеңіс (макс)",
      Решение_теста_по_ЕНТ: "ҰБТ бойынша тест шешімі (макс)",
      Лучший_ученик_который_наберет: "Ал ең көп сoins жинаған және ҰБТ-да 120+ балл жинаған үздік оқушы Iphone15 жеңіп алады",
    },
    tournament: {
      Турнир_знаний: "Турнир білімі -",
      Показатель: "сіздің деңгейіңіздің көрсеткіші.",
      Докажи: "Мықты команда екеніңізді дәлелдеңіз!",
    },
    inner_course: {
      Достижения: "Достижения",
      Система_жизни: "Жан жүйесі",
      Телеграм_чат_с_кураторами: "Куратормен телеграмм чат",
    },
    lesson: {
      Урок: "сабақ",
      Посмотреть_запись: "Сабақтың жазбасын көру",
      Домашнее_задание: "Үй жұмысы",
      Открыть_конспект: "Конспектіні көру",
      Выполнено : "орындалды",
    },
    general: {
      Подробнее_о_курсе: "Курс жайлы",
    }
  },
  [namespaces.pages.hello]: {
    welcome: "Казахский",
  },
};