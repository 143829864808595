import React, {  FC, useState } from "react";
import { useAppSelector } from "src/hooks/redux";
import "../Header/Header.scss";
import { useAppDispatch } from "src/hooks/redux";
import { useNavigate } from "react-router-dom";
import { UserSlice } from "src/service/reducers/UserSlice";

export const Header_teacher: FC<{ isEmpty?: boolean }> = ({ isEmpty }) => {  
  if (isEmpty) {
    return (
      <div className="empty-header">
        <img className="logo" src="assets/svg/EasyEnt.svg" />
      </div>
    );
  }
  const navigate = useNavigate();
  const { signOutUser } = UserSlice.actions;
  const dispatch = useAppDispatch();

  const user = useAppSelector((store) => store.user.data);
  const signOut = function () {
    dispatch(signOutUser(user));
    navigate("/login");
  };
  const isUserHaveImage = (
    <div className="img">
      <img
        src={
          user.image === null
        
            ? "/assets/svg/ava.svg"
            : user.image
        }
      />
    </div>
  );

  const [, setShowMenu] = useState(false);

  const handleShowMenu = () => {
    setShowMenu((s) => !s);
  };

  const showUserName = `${user.full_name}`;
  return (
    <header className="header header--teacher">
      <div className="container">  
        <div className="user-info">
          {isUserHaveImage}
          <div className="person-name">
            <p onClick={handleShowMenu}>
              {showUserName} 
            </p>
          </div>
        </div>
        <span className="btn logout" onClick={signOut.bind(this)}>
          Выход
        </span>
      </div>
    </header>
  );
};
