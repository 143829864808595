import axios from "axios";
import { createAsyncThunk } from "@reduxjs/toolkit";
import { userApi } from "./userApi";

export const fetchTeachers = createAsyncThunk("teachers/fetch", async () => {
  const res = await axios.get("db/teachers.json");
  return res.data;
});

export const fetchCourses = createAsyncThunk("courses/fetch", async () => {
  const res = await axios.get("db/courses.json");
  return res.data;
});

export const fetchShoppingPage = createAsyncThunk(
  "shopping/fetch",
  async () => {
    const requestOne = axios.get("db/prizes.json");
    const requestTwo = axios.get("db/products.json");

    const res = await axios.all([requestOne, requestTwo]).then(
      axios.spread((...res) => {
        return { prizes: res[0].data, products: res[1].data };
      })
    );
    return res;
  }
);

export const getUser = createAsyncThunk("user/get", async () => {
  const res = await userApi.fetchById();
  return res.data;
});

export const getMe = createAsyncThunk("user/getMe", async () => {
  const res = await userApi.fetchMe();
  return res.data;
});

export const login = createAsyncThunk("user/login", async (user: any) => {
  const res = await userApi.auth(user.email, user.password, user.client_id);
  return res.data;
});

export const forgetpassword = createAsyncThunk("user/forgetpassword", async (user: any) => {
  const res = await userApi.forgetpassword(user.email);
  return res.data;
})