import axios from "axios";
import moment from "moment";
import { toast } from "react-toastify";
import { FC, useState } from "react";
import { useForm } from "react-hook-form";
import { AdminUser, ICourse, User_feeds } from "src/interface";
import { authHeader } from "src/utils/authHeader";

import "./AddCourse.scss";
interface ICourses {
  course_id: number;
  start_date: string;
  end_date: string;
}
export const AddCourse: FC<{
  coursesList: ICourse[];
  user: AdminUser;
}> = ({ user, coursesList }) => {
  const [, setUserFeeds] = useState<User_feeds[]>([]);
  const [, setSendUserFeed] = useState<boolean>(true);
  const [userCourses, setUserCourses] = useState<ICourses[]>([]);
  const [courseStartDate, setCourseStartDate] = useState("");
  const [courseEndDate, setCourseEndDate] = useState("");
  const [sended,setSended] = useState(false);

  const { handleSubmit, register } = useForm();

  const onSubmit = async (data: any) => {
    const updatedData = {
      ...data,
      is_full: false,
      status: "payed",
      package: "standard",
      positions: userCourses,
      created_at: moment(),
      created_by: 0,
    };
    if (!sended){
      axios
      .post(`/api/v1/order/prepaid/${user.id}/`, updatedData, {
        headers: authHeader(),
      })
      .then((response) => {
        if (response.status == 200) {
          console.log(response);
          setUserFeeds((prevState) => [...prevState, response.data]);
          setSendUserFeed(true);
          toast.success("Оплата записано!");
        }
      })
      .catch((error) => {
        toast.error(error.response.data.detail[1]);
      }).finally(()=>{
        setSended(true);
      });
    } else {
      toast.warn("Нельзя заново отправлять одно и тоже!");
    }
    
  };
  const sendUserCourses = (id: number, isChecked: boolean) => {
    if (isChecked) {
      setUserCourses((prevCourses) => [
        ...prevCourses,
        { course_id: id, start_date: courseStartDate, end_date: courseEndDate },
      ]);
    } else {
      setUserCourses((prevCourses) =>
        prevCourses.filter((course) => course.course_id !== id)
      );
    }
  };
  const renderCoursesList = coursesList.map((course) => {
    if (!course.active) {
      return;
    }
    return (
      <li className="course-elem">
        <input
          key={course.id}
          onChange={(e) => sendUserCourses(course.id, e.currentTarget.checked)}
          type="checkbox"
          id={course.name}
          name={course.name}
        />
        <label htmlFor={course.name}>{course.name}</label>
      </li>
    );
  });
  return (
    <>
      <div>
        <div className="add-course-wrapper">
          <form action="" onSubmit={handleSubmit(onSubmit)}>
            <div className="add-course-element">
              <label htmlFor="start_date"> Начало обучение </label>
              <input
                id="start_date"
                type="date"
                onInput={(e) => { setSended(false);setCourseStartDate(e.currentTarget.value);}}
                {...register("start_date", { required: true })}
                name="start_date"
                required
              />
            </div>
            <div className="add-course-element">
              <label htmlFor="end_date"> Конец обучение </label>
              <input
                id="end_date"
                type="date"
                onInput={(e) => { setSended(false);setCourseEndDate(e.currentTarget.value);}}
                {...register("end_date", { required: true })}
                name="end_date"
                required
              />
            </div>
            <h4>Курсы:</h4>
            <ul className="course-elements">{renderCoursesList}</ul>
            <button type="submit" className="btn btn-primary btn-add-course">
              Добавить платежку
            </button>
          </form>
        </div>
      </div>
    </>
  );
};
