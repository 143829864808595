import axios from "axios";
import React, { useEffect, useState } from "react";
import { useParams, useLocation } from "react-router-dom";
import { ILesson } from "src/interface";
import { Link } from "react-router-dom";
import "./LessonVideoPage.scss";
import { authHeader } from "src/utils/authHeader";

export const LessonVideoPage = () => {
  document.title = "Запись урока | EasyENT";
  const params = useParams();
  const location: any = useLocation();
  const [lesson, setlesson] = useState<ILesson>({});
  useEffect(() => {
    axios
      .get("/api/v1/lessons/" + params.id, { headers: authHeader() })
      .then((response) => setlesson(response.data));
  }, []);
  const lesson_day: any = lesson.start_date ? lesson.start_date : "";
  return (
    <>
      <ul className="breadcrumbs">
        <li>
          {location?.state?.isParent ? (
            <Link to="/parent">Мой ребенок</Link>
          ) : (
            <Link to="/my-courses">Мои курсы</Link>
          )}
        </li>
        <li>
          <span>
            <i className="fa-solid fa-arrow-right"></i>
          </span>
        </li>
        <li>
          {location?.state?.isParent ? (
            lesson?.course?.name
          ) : (
            <Link to={`/course_${lesson?.course_id}`}>
              {lesson?.course?.name}
            </Link>
          )}
        </li>
        <li>
          <span>
            {lesson?.course_id && <i className="fa-solid fa-arrow-right"></i>}
          </span>
        </li>
        <li>
          <span>
            {lesson?.course_id && `Просмотреть запись - ${lesson?.name}`}
          </span>
        </li>
      </ul>
      <div className="video-lesson box">
        <div className="video-lesson__top">
          <div className="video-lesson__left">
            <span className="video-lesson__heading">{lesson.name}</span>
            <span className="video-lesson__date">
              <i className="fa-solid fa-clock"></i> {lesson_day}
            </span>
          </div>
        </div>
        {lesson.video_url !== null && lesson.video_url !== "" ? (
          <div className="video-lesson__img">
            <iframe
              src={lesson.video_url}
              title="YouTube video player"
              allow="accelerometer; autoplay;modestbranding; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
              allowFullScreen
            />
          </div>
        ) : lesson.zoom_link !== null ? (
          <a target="_blank" rel="noreferrer" href={lesson.zoom_link}>
            {lesson.zoom_link}
          </a>
        ) : (
          <p>Видеоурока нету</p>
        )}
      </div>
    </>
  );
};
