import axios from "axios";
import React, { useEffect, useState } from "react";
import { useAppSelector } from "src/hooks/redux";
import { User_feeds, OrderShort } from "src/interface";
import { authHeader } from "src/utils/authHeader";
import { Tabs } from 'react-simple-tabs-component'
import "./SubscriptionsPage.scss";
export const SubscriptionsPage = () => {
  document.title = "Мои оплаты | EasyENT";
  const [userFeeds, setUserFeeds] = useState<User_feeds[]>([]);
  const user = useAppSelector((store) => store.user.data);
  const [orders, setOrders] = useState<OrderShort[]>([]);
  useEffect(() => {
    if(!user.id){
      console.log('пустo')
    }
    else{
      setUserFeeds(user.user_feeds);
      axios
      .get("/api/v1/order/my",{ headers: authHeader()})
      .then((response) => setOrders(response.data));
    }
  }, []);
  // const subscriptions = useAppSelector(
  //   (store) => store.user.data.subscriptions
  // );
  const TabSt = () => {
    const order = orders.filter((o)=> o.start_date == '2024-09-11').sort((o)=>o.id).reverse()[0];
    return (
      <>      
      {order ? 
      <div className="payment-item">
          <div className="payment-row">  
            <p className="payment-row__title">Номер заказа</p>
            <p>{order ? order.id : "Загрузка"}</p>
          </div>
          <div className="payment-row">  
            <p className="payment-row__title">Мои курсы</p>
            <ul>
              {order.positions ? order.positions.map((item:any) => 
                <li key={item.order_id}>
                  {item.course?.name}
                </li> 
              ) : ""}
            </ul>
          </div>
          <div className="payment-row">  
            <p className="payment-row__title">Стоимость</p>
            <p>{order ? order.total_amount : "Загрузка"}</p>
          </div>
          <div className="payment-row">    
            <p className="payment-row__title">Статус</p>
              {userFeeds.filter(e => e.start_date == "2024-04-11").length > 0 || userFeeds.filter(e => e.start_date == "2024-04-13" ).length > 0 || userFeeds.filter(e => e.start_date == "2024-04-15" ).length > 0  ?
                <p><i className="fa-solid fa-check"></i>Оплачено</p> : <p><i className="fa-solid fa-xmark"></i> 
                Не оплачено</p>
              }
          </div>
            {order !== undefined  ?  
              <div>
                <form target="_blank" method="post" action="https://kaspi.kz/online" id="formOnlinePayment">
                    <input type="hidden" name="TranId" value={order.id + ""}/>
                    <input type="hidden" name="OrderId" value={order.id + ""}/>
                    <input type="hidden" name="Amount" value={(order.total_amount * 100) + ""}/>
                    <input type="hidden" name="Service" value="StayStudy"/>
                    <input type="hidden" name="ReturnUrl" value={"https://app.easyent.kz/transaction/success?id="+order.id}/>
                    <button className="btn btn-primary btn-flex">Оплатить через Kaspi</button>
                </form> 
                <div className="payment-info">
                  <span className="payment-info__heading2">Если оплата не выходит</span>
                  <ul className="step-ul">  
                      <li>Оплата инструкция1</li> 
                      <li>Оплата инструкция2</li>  
                      <li>Оплата инструкция3</li> 
                      <li>Оплата инструкция4</li> 
                      <li>Оплата инструкция5</li> 
                  </ul>
                </div>
                <div className="payment-info">
                  <span className="payment-info__heading2">Если цена неверная</span>
                  <p>Напишите пожалуйста</p>
                </div> 
              </div> : "-" 
            }
        </div>
         : <div>
            Платежки нету
         </div>
      }
      </>
    )
  }
  const tabs = [
    {
      label: "Сентябрь",
      Component: TabSt
    },
  ]
  return (
    <div className="subscription-content">
      <h1>Мои_оплаты</h1>
      <Tabs tabs={tabs} />
    </div>
  );
};
