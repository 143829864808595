import React from "react";
import { useNavigate } from "react-router-dom";

import "./NotFoundPage.scss";

export const NotFound = () => {
  document.title = "Страница не найдена! | EasyENT";
  const navigate = useNavigate();
  const goBack = () => {
    navigate(-1);
  };

  return (
    <div className="error-not-content">
      <div className="error-not-found">
        <h1>404</h1>
        <h3>Страница не найдена!</h3>
        <p>Что-то пошло не так!</p>
        <div onClick={goBack} className="btn btn-dashed btn-flex">
          <i className="fa-solid fa-angle-left"></i>Назад
        </div>
      </div>
    </div>
  );
};
