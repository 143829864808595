/* eslint-disable no-unsafe-optional-chaining */
import React, { useEffect, useState } from "react";
import { useNavigate, Link, useLocation } from "react-router-dom";
import axios from "src/utils/axios";
import { authHeader } from "src/utils/authHeader";
import { Preloader } from "src/components";
import "./ParentChildCourse.scss";

export const ParentChildCourse = () => {
  document.title = "Тесты | EasyENT";
  const location: any = useLocation();
  const [loadingSubjects, setLoadingSubjects] = useState(false);
  const [loadingProgress, setLoadingProgress] = useState(false);
  const [subjects, setSubjects] = useState<any>([]);
  const [courseProgress, setCourseProgress] = useState<any>({});
  const navigate = useNavigate();

  function getChildCourseProgress(course_id: number, user_id: number) {
    setLoadingProgress(true);
    axios
      .get(`/api/v1/courses/${course_id}/userprogress?user_id=${user_id}`, {
        headers: authHeader(),
      })
      .then((res: any) => {
        setCourseProgress(res?.data);
      })
      .finally(() => setLoadingProgress(false));
  }

  function getChildSubjects(course_id: number, user_id: number) {
    setLoadingSubjects(true);
    axios
      .get(`/api/v1/courses/${course_id}/lesson_progress?user_id=${user_id}`, {
        headers: authHeader(),
      })
      .then((res: any) => {
        setSubjects(res?.data);
      })
      .finally(() => setLoadingSubjects(false));
  }

  useEffect(() => {
    if (location?.state?.user_id && location?.state?.course_id) {
      getChildCourseProgress(
        location?.state?.course_id,
        location?.state?.user_id
      );
      getChildSubjects(location?.state?.course_id, location?.state?.user_id);
    }
  }, [location]);

  return (
    <div className="parent-child-course-page">
      <ul className="breadcrumbs">
        <li>
          <Link to="/parent">Мой ребенок</Link>
        </li>
        <li>
          <span>
            <i className="fa-solid fa-arrow-right"></i>
          </span>
        </li>
        <li>
          <span>{location?.state?.course_name}</span>
        </li>
      </ul>
      <h1 className="title">{location?.state?.course_name}</h1>
      {loadingProgress ? (
        <Preloader />
      ) : (
        <div className="card-user">
          <h3>{location?.state?.user_full_name}</h3>
          <h4>{courseProgress?.course_name}</h4>
          <div>Успеваемость: {Math.round(courseProgress?.progress)}</div>
          <div>Выполнено ДЗ: {courseProgress?.completed_homework}</div>
          <div>Средний балл: {Math.round(courseProgress?.average_rating)}</div>
        </div>
      )}
      <h1 className="sub-title">Список уроков</h1>
      <div className="card-container">
        {loadingSubjects ? (
          <Preloader />
        ) : (
          subjects?.length &&
          subjects?.map((subject: any) => (
            <div key={subject?.id} className="card">
              <h4>{subject?.name}</h4>
              <div>
                <button
                  type="button"
                  className="btn btn-outlined"
                  onClick={() => {
                    navigate(
                      `/course_${location?.state?.course_id}/homework_${subject?.id}`,
                      {
                        state: {
                          isParent: true,
                        },
                      }
                    );
                  }}
                >
                  ДЗ:{" "}
                  {subject?.homeworks?.length ? "Выполнено" : "Не выполнено"}
                </button>
                <button
                  type="button"
                  className="btn btn-outlined"
                  onClick={() => {
                    navigate(
                      `/course_${location?.state?.course_id}/lesson_video_${subject?.id}`,
                      {
                        state: {
                          isParent: true,
                        },
                      }
                    );
                  }}
                >
                  Смотреть урок
                </button>
              </div>
            </div>
          ))
        )}
      </div>
    </div>
  );
};
