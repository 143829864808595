import React, { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { Header, Footer } from "src/components";
import axios from "axios";
// import { useAppSelector } from "src/hooks/redux";
import { useSearchParams} from "react-router-dom";


export const ApprovalPage = () => {
  document.title = "Потверждение почты";

  const navigate = useNavigate();

  // const isLoggedIn = useAppSelector((store) => store.user.loggedIn);
  const [searchParams] = useSearchParams();
  
  useEffect(() => { 
    const VerifyData = JSON.stringify({"token": searchParams.get("token")});
    console.log(VerifyData);
    axios
      .post("/api/v1/users/verify", VerifyData, {headers: {
        'Content-Type': 'application/json'
      }})
      .then(() => navigate("/login"));
  }, [searchParams.get("token")]);


  return (
    <div className="app-container">
      <Header isEmpty={true} />
      <div className="content-wrapper">
        <div className="login-container">
          <img src="assets/login.png" />
          <form className="login-form">

          </form>
        </div>    
        {/* <Login /> */}
      </div>
      <Footer />
    </div>
  );
};
export default ApprovalPage;