import { createSlice } from "@reduxjs/toolkit";
import { getJWT } from 'src/utils/authHeader';


const initialState = {
  data: {
    access_token: "",
    token_type: "",
    fullName: {
      firstName: "",
      middleName: "",
      lastName: "",
    },
    full_name: "",
    id: 0,
    image: "",
    phone: "",
    postalCode: "",
    is_teacher: false,
    is_superuser: false,
    is_parent: false,
    children: [],
    password: "",
    personData: false,
    mailAdvertising: false,
    publicOffers: false,
    coins: 0,
    course_price: 0,
    notification: [],
    zoom: "",
    lang: "",
    is_manager: false,
    user_feeds: [{
      user_id: 0,
      course_id: 0,
      start_date: "",
      end_date: "",
      status: "",
    }],
    subscriptions: [
      {
        id: 1,
        active: false,
        name: "Пакет из 5 предметов",
        courses: [
          { id: 1, name: "Авторский курс по Истории Казахстана" },
          { id: 2, name: "Авторский курс по Грамотности Чтения" },
          { id: 3, name: "Авторский курс по Математической Грамотности" },
          { id: 4, name: "Авторский курс по Физике" },
          { id: 5, name: "Авторский курс по Математике" },
        ],
        packetType: "Премиум",
        subscriptionPeriod: "Ежемесячно",
        cost: 32200,
      },
    ],
    courses: [
      { id: 1, watched: true, conspected: true, homeWork: false }
    ],
  },
  loggedIn: false,
};
// const loggedState = {
//   data: {
//     fullName: {
//       firstName: "Nurzhan",
//       middleName: "",
//       lastName: "Altaev",
//     },
//     image: "",
//     phone: "+123456789",
//     postalCode: "136402",
//     is_teacher: false,
//     is_superuser: false,
//     password: "qwerty",
//     personData: true,
//     is_manager: false,
//     lang: "",
//     mailAdvertising: true,
//     publicOffers: true,
//     coins: 1000,
//     course_price: 0,
//     zoom: "",
//     user_feeds: [{
//       user_id: 0,
//       course_id: 0,
//       start_date: "",
//       end_date: "",
//       status: "",
//     }],
//     subscriptions: [
//       {
//         id: 1,
//         active: false,
//         name: "Пакет из 5 предметов",
//         courses: [
//           { id: 1, name: "Авторский курс по Истории Казахстана" },
//           { id: 2, name: "Авторский курс по Грамотности Чтения" },
//           { id: 3, name: "Авторский курс по Математической Грамотности" },
//           { id: 4, name: "Авторский курс по Физике" },
//           { id: 5, name: "Авторский курс по Математике" },
//         ],
//         packetType: "Премиум",
//         subscriptionPeriod: "Ежемесячно",
//         cost: 32200,
//       },
//     ],
//     courses: [
//       { id: 1, watched: true, conspected: true, homeWork: false },
//       { id: 2, watched: true, conspected: false, homeWork: false },
//       { id: 5, watched: true, conspected: false, homeWork: false },
//     ],
//     notification: [
//       { id: 1, name: `Скоро пройдет прямой эфир "3 урок: Эпоха камня!"` },
//     ],
//   },
//   loggedIn: true,
// };

const TokenExisted = {
  data: {
    access_token: "",
    token_type: "",
    fullName: {
      firstName: "",
      middleName: "",
      lastName: "",
    },
    full_name: "",
    id: 0,
    image: "",
    phone: "",
    postalCode: "",
    is_superuser: false,
    is_teacher: false,
    is_parent: false,
    children: [],
    password: "",
    lang: "",
    is_manager: false,
    personData: false,
    mailAdvertising: false,
    publicOffers: false,
    coins: 0,
    notification: [],
    zoom: "",
    course_price: 0,
    user_feeds: [{
      user_id: 0,
      course_id: 0,
      course_name: "",
      start_date: "",
      end_date: "",
      status: "",
    }],
    subscriptions: [
      {
        id: 1,
        active: false,
        name: "Пакет из 5 предметов",
        courses: [
          { id: 1, name: "Авторский курс по Истории Казахстана" },
          { id: 2, name: "Авторский курс по Грамотности Чтения" },
          { id: 3, name: "Авторский курс по Математической Грамотности" },
          { id: 4, name: "Авторский курс по Физике" },
          { id: 5, name: "Авторский курс по Математике" },
        ],
        packetType: "Премиум",
        subscriptionPeriod: "Ежемесячно",
        cost: 32200,
      },
    ],
    courses: [
      { id: 1, watched: true, conspected: true, homeWork: false }
    ],
  },
  loggedIn: true,
}

let localStored = false;

if (localStorage.getItem('user')){
  localStored = true;
  Object.assign(TokenExisted.data, getJWT());
}

export const UserSlice = createSlice({
  name: "user",
  initialState: localStored ? TokenExisted : initialState,
  reducers: {
    registerUser(state, action) {
      state.loggedIn = true;
      Object.assign(state.data, action.payload);
    },
    setToken(state, action) {
      state.loggedIn = true;
      Object.assign(state.data, action.payload.payload);
    },
    updateUser(state, action) {
      Object.assign(state.data, action.payload);
    },
    signOutUser(state, action) {
      state.loggedIn = false;
      Object.assign(state.data, action.payload);
      localStorage.removeItem("user");
    }
  },
});

export default UserSlice.reducer;
