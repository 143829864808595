import React, { useState } from "react";
import { useAppDispatch, useAppSelector } from "src/hooks/redux";
import { EditorForm } from "./EditorForm";
import { UserSlice } from "src/service/reducers/UserSlice";
import { authHeader } from "src/utils/authHeader";
import "./ProfileEditor.scss";
import axios from "axios";

export const ProfileEditor = () => {
  const { updateUser } = UserSlice.actions;
  const store = useAppSelector((store) => store.user.data);
  const dispatch = useAppDispatch();
  const [user, setUser] = useState(store);

  const handleSubmit = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    dispatch(updateUser(user));
  };

  const handleChangeName = (e: React.ChangeEvent<HTMLInputElement>) => {
    const element = e.target as HTMLInputElement;
    const name = element.name;
    const value = element.value;
    const newUser = Object.assign({}, user);
    newUser.fullName = { ...newUser.fullName, [name]: value };
    setUser(newUser);
  };

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const element = e.target as HTMLInputElement;
    const name = element.name;
    const value = element.value;
    setUser((prevState) => ({ ...prevState, [name]: value }));
  };
  
  const UploadPhoto = (event: React.ChangeEvent<HTMLInputElement>) => {
    const formdata = new FormData();
    if (event.target.files?.length){
      formdata.append('file', event.target.files[0]);
      axios.post('/api/v1/users/image', formdata, { headers: authHeader() }).then(res=>{
        alert("Success");
        console.log(res);
      })  
    }
  }

  return (
    <div className="editor-container">
      <div className="image-wrapper" style={{ backgroundImage: "url(/assets/svg/avatar2.svg)" }}>
        <input onChange={UploadPhoto} type="file"></input>
        <img
          src={
            user.image === ""
              ? "https://st3.depositphotos.com/6672868/13701/v/600/depositphotos_137014128-stock-illustration-user-profile-icon.jpg"
              : user.image
          }
        />
      </div>
      <EditorForm
        user={user}
        handleChangeName={handleChangeName}
        handleSubmit={handleSubmit}
        handleChange={handleChange}
      />
    </div>
  );
};
