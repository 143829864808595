import axios from "axios";
import toast from "src/utils/toast";

const exceptions: string[] = [];

axios.interceptors.response.use(
  (response) => {
    return response;
  },
  (error) => {
    if (
      (error?.response?.status === 500 ||
        error?.response?.status === 409 ||
        error?.response?.status === 400 ||
        error?.response?.status === 401) &&
      !exceptions.includes(error?.response?.config?.url)
    ) {
      toast(
        error?.response?.status === 500
          ? "Ошибка на сервере"
          : error?.response?.data?.detail,
        "error"
      );
    }
    return Promise.reject(error);
  }
);

export default axios;
