import { useEffect, useRef, useMemo, EffectCallback, DependencyList } from 'react';

function useSkipInitialEffect(effect: EffectCallback, deps?: DependencyList): void {
  const initialRender = useRef(true);
  const memoEffect = useMemo(() => effect, [deps]);

  useEffect(() => {
    if (initialRender.current) {
      initialRender.current = false;
    } else {
      memoEffect();
    }
  }, deps);
}

export { useSkipInitialEffect };
