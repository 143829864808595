import axios from "axios";
import React, { useEffect, useState } from "react";
// import { Link } from "react-router-dom";

import "./AdminCoins.scss";
import { authHeader } from "src/utils/authHeader";

export const AdminCoins = () => {
  document.title = "Тесты | EasyENT";
  const [, setTests] = useState<any>([]);

  useEffect(() => {
    axios
      .get("/api/v1/tests/tests/?skip=0&limit=50", { headers: authHeader() })
      .then((response) => setTests(response.data));
  }, []);

  return (
    <div className="my-course">
      <h1 className="title">Коины</h1>

    </div>
  );
};
