import React, { FC, useState } from "react";
import { Link } from "react-router-dom";
import { useAppSelector } from "src/hooks/redux";
import "./Header.scss";
import { useTranslation } from "react-i18next";
import { namespaces } from "src/i18n/i18n.constants";

export const Header: FC<{ stateNavShow?:(value: boolean) => void, isEmpty?: boolean }> = ({ stateNavShow, isEmpty }) => {  
  const { i18n } = useTranslation(namespaces.pages.hello);
  const changeLanguage = (language: string) => () => {
    i18n.changeLanguage(language);
    localStorage.setItem('language', JSON.stringify(language));
    setInterfacelanguage(language);
  };
  let defaultLang = "";
  const language = localStorage.getItem('language');
  if(typeof language !== 'undefined' && language !== null){
    if(JSON.parse(language) == "ru"){
        defaultLang = "ru";
    }
    else{
       defaultLang = "kz";
    }
  }
  const [Interfacelanguage, setInterfacelanguage] = useState(defaultLang);
  if (isEmpty) {
    return (
      <div className="empty-header">
        <div className="empty-header__container">
          <Link to="/Login"><img className="logo" src={Interfacelanguage == "ru" ? "/assets/svg/EasyEnt.svg" : "/assets/svg/easyUBT.svg"} /></Link>
          <ul className="lang-ul">
            <li className={Interfacelanguage == "ru" ? "active" : ""} onClick={changeLanguage("ru")}>Рус</li>
            <li>/</li>
            <li className={Interfacelanguage == "kz" ? "active" : ""} onClick={changeLanguage("kz")} >Каз</li>
          </ul>
        </div>  
      </div>
    );
  }
  const user = useAppSelector((store) => store.user.data);
  

  const isUserHaveImage = (
    <div className="img">
      <img
        src={
          user.image === null
        
            ? "/assets/svg/ava.svg"
            : user.image
        }
      />
    </div>
  );
  const [showMenu, setShowMenu] = useState(false);
  const handleShowMenu = () => {
    setShowMenu((s) => !s);
  };

 
  const showNotificationsCount =
    user.notification.length === 0 ? (
      ""
    ) : (
      <span className="notification-count">{user.notification.length} </span>
    );

  const showUserName = `${user.full_name}`;
  const [showNav, setShowNav] = useState(false);    
  const handleShowNav = () => {
    if(stateNavShow !== undefined){
      stateNavShow(!showNav);
      setShowNav(!showNav);
    }
  }
  return (
    <header className="header">
      <div className="container">  
        <div onClick={handleShowNav} className="mob-start">
          <span className="menu1"></span>
          <span className="menu2"></span>
          <span className="menu3"></span>
        </div>
        <div className="header__right">
          <div className="user-info">
            {isUserHaveImage}
            <div className="person-name">
              <p onClick={handleShowMenu}>
                {showUserName} <i className="fa-solid fa-angle-down"></i>
              </p>
              <div className="advancedMenu" style={{ display: showMenu ? "block" : "none" }}>
                <Link onClick={handleShowMenu} to="/my-profile">
                  Мой профиль
                </Link>
                <Link onClick={handleShowMenu} to="/subscriptions">
                  Мои оплаты
                </Link>
              </div>
            </div>
            <div className="notification">
              <i className="fa-solid fa-bell"></i>
              {showNotificationsCount}
            </div>
          </div>
          <div className="header__dekright">
            {/* <ul className="lang-ul">
              <li className={Interfacelanguage == "ru" ? "active" : ""} onClick={changeLanguage("ru")}>Рус</li>
              <li>/</li>
              <li className={Interfacelanguage == "kz" ? "active" : ""} onClick={changeLanguage("kz")} >Каз</li>
            </ul> */}
            {/* <Link to="achievement" className="coins">
              <p>
                StudyCoins: <span>{user.coins.toFixed(2)}</span>
              </p>
              <i className="fa-solid fa-coins"></i>
            </Link>          */}
          </div>
        </div>
      </div>
    </header>
  );
};
