import React from "react";
import PB from "@ramonak/react-progress-bar";

import "./ProgressBar.scss";

type TProps = {
  completed: number;
};

export function ProgressBar({ completed }: TProps) {
  return (
    <div className="progress-bar">
      <PB
        className="wrapper"
        bgColor="#3561ff"
        baseBgColor="#BEBFDD"
        labelAlignment="outside"
        labelColor="#000000"
        height="15px"
        completed={Math.round(completed)}
      />
      {/* <div className="progress-bar__description">
        {completed}% (средняя успеваемость)
      </div> */}
    </div>
  );
}
