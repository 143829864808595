import React from "react";
import "./Footer.scss";

export const Footer = () => {
  return (
    <footer className="footer">
      <div className="footer__wrapper">
        <div className="footer__item footer__item--first">          
          <div className="footer-first">
            <img className="footer-first__img" src="/assets/svg/logo--footer.svg" />
            <p>© EasyENT, 2023</p> 
          </div>           
        </div>
        <div className="footer__item">
          <span className="footer__heading">О EasyENT</span>
          <p>Договор оферты</p>
          <p>Политика конфиденциальности</p>         
        </div>
        <div className="footer__item">
          <span className="footer__heading">Контактные данные</span>
          <ul className="footer-cont">
            <li>
              <span>Телефон для связи</span>
              +7 708 555 01 42
            </li>
            <li>
              <span>Адрес</span>
              г. Нур-Султан, ул. Улы дала 11.3
            </li>
          </ul>    
        </div>
        <div className="footer__item">
          <span className="footer__heading">Мы в соц.сетях</span>
          <div className="footer-links">
            <a>
              <img src="/assets/svg/instagram.svg" />
            </a>
            <a href="#">
              <img src="/assets/svg/telegram.svg" />
            </a>
            <a href="#">
              <img src="/assets/svg/whatsapp.svg" />
            </a>  
          </div>
        </div>
      </div>
    </footer>
  );
};
