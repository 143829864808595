import axios from "axios";
import React, {useEffect, useState, FC } from "react";
import { authHeader } from "src/utils/authHeader";
import { ICourse } from "src/interface";
import Select from "react-select";
import "./ModalHomework.scss";

export const ModalHomework: FC<{active:boolean,setActive:(value:boolean) => void, lessonid:number}> = 
  ({active, setActive,lessonid}) => {
 const [activeItemIndex, setActiveItemIndex] = useState<any>();
 const [allCourses, setAllCourses] = useState<ICourse[]>([]);
 const [valueCourse, setValueCourse] = useState<any>({});
 const [selectCourses, setselectCourses] = useState<ICourse[]>([]);
 const [courseLessons, setcourseLessons] = useState<any[]>([]);
 const [lessonQuestions, setLessonQuestions] = useState<any[]>([]);
 const [сopyQuestions, setCopyQuestions] = useState<any>([]);

 const [sendForm, setSendForm] = useState<boolean>(true);
 useEffect(() => {
  axios
    .get("/api/v1/courses/my/",{ headers: authHeader()})
    .then(
      (response) => setAllCourses(response.data)
    );
  }, []);
  let newArrayOfObj:any = [];
  function isEmpty(obj:any) {
    return Object.keys(obj).length === 0;
  }
  useEffect(() => {
    if(allCourses.length > 0){
      newArrayOfObj = allCourses.map(({
        name: label,
        id: value,
        ...rest
      }) => ({
        label,value,
        ...rest
      }));
      setselectCourses(newArrayOfObj);
      setValueCourse(newArrayOfObj[0]);
    }
  }, [allCourses])
  
  useEffect(() => {
    if(!isEmpty(valueCourse) && valueCourse.value !== undefined){
      axios
      .get("/api/v1/courses/" + valueCourse.value + "/lessons/", {
        headers: authHeader(),
      })
      .then((response) => {
        setcourseLessons(response.data);
      });
    }
  }, [valueCourse,сopyQuestions]);

  const getLessonHomework = function (id:number) {
    axios
      .get("/api/v1/lessons/" + id, { headers: authHeader() })
      .then((response) => {
        setLessonQuestions(response.data.questions);
      })
  }
  
  const copyQuestion = function (id:number,les_id:number) {
    if(сopyQuestions.indexOf(id) > -1){
      console.log('da');  
    }
    else{
      сopyQuestions.push(id);
      setCopyQuestions(сopyQuestions);
      getLessonHomework(les_id);
    }
  }
  const SubmitNewQuestion = function () {
    setSendForm(false);
    const newquestion = {
      "question_ids": сopyQuestions,
      "new_lesson_id": lessonid
    }
    axios
      .post("/api/v1/question/duplicate", newquestion, { headers: authHeader() })
      .then((response) => {
        if (response.status == 200) {
          window.location.reload();
        }
      })
  }
  return (
    <div className={active ? "modal-photo active" : "modal-photo"} onClick={()=> setActive(false)}>
      <div className="modal-content" onClick={e => e.stopPropagation()}>
        <h1 className="modal-content__heading">Добавление вопросов</h1>
        <div className="modal-select">
          <Select
            value={valueCourse}
            options={selectCourses}
            onChange={(option: any) => setValueCourse(option)}
            styles={{
              control: (baseStyles, state) => ({
                ...baseStyles,
                borderColor: state.isFocused ? '#555' : '#555',
                borderWidth: state.isFocused ? '2px' : '2px',
                backgroundColor: state.isFocused ? '#f3f3f3' : '#f3f3f3',
              })
            }}  
          />
        </div>
        <ul className="hw-list">
          {courseLessons.length > 0 ? courseLessons.map((lesson, index) => {
              return (
                <li key={lesson.id}>
                  <div className={activeItemIndex == index ? "hw-item active" : "hw-item"}>
                    <div className="hw-item__top">
                    {index + 1}) {lesson.name}
                      <span onClick={() => (
                          activeItemIndex == index ? (
                            setActiveItemIndex(9999),
                            console.log(55)
                            ) : (
                              setLessonQuestions([]),
                              setActiveItemIndex(index),
                              getLessonHomework(lesson.id)
                            )
                          )
                        } 
                        className="hw-item__right">
                        <i className="fa-solid fa-angle-down"></i>
                      </span>
                    </div>
                    <div className="hw-item__area">
                      <ul className="homework-list">
                        {lessonQuestions.length > 0 ? lessonQuestions.map((question, index) => {
                          return (
                            <li key={index}>
                              <span className="hw-item__question">
                                {question.display_name}
                              </span>
                              <div className="question-row__area">
                                {question["question_type"] === "choice" && question["question_choise"]["values"] ? question["question_choise"]["values"].map(
                                  (choice:any) => (
                                    <div key={choice.id} className="mininput-row">
                                      <input name="value186" type="radio" value="1231312"/>
                                      <label className="input-radio">{choice.value}</label>
                                    </div>
                                  )
                                ) : "пусто"}
                                </div>
                                {сopyQuestions.indexOf(question.id) > -1 ? 
                                  <button className="btn btn-mini btn-green">Добавлено</button> : 
                                  <button className="btn btn-mini btn-primary" onClick={()=> copyQuestion(question.id,lesson.id)}>Добавить</button>
                                }
                            </li>
                            )
                          }
                        ) : "Вопросов нету"}  
                      </ul>
                    </div>
                  </div>
                </li>
              );
            }) : "Уроков нету"}
          </ul>
          <div className="btn-modal">
            <button onClick={sendForm == true ? () => SubmitNewQuestion() : () => console.log('отправляется')} className="btn btn-primary">Выполнить добавление</button> 
          </div>
      </div>
    </div>
  )
}
