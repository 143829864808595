import React, { FC } from "react";
import "./ModalPhoto.scss";

export const ModalPhoto: FC<{active:boolean,setActive:(value:boolean) => void}> = 
  ({active, setActive}) => {
  return (
    <div className={active ? "modal-photo active" : "modal-photo"} onClick={()=> setActive(false)}>
      <div className="modal-photo__content" onClick={e => e.stopPropagation()}>
        <img src="/assets/rasp-new.jpeg"></img>
      </div>
    </div>
  )
}
