import React from "react";
import { Outlet, Navigate } from "react-router-dom";
import { Footer, Header_teacher, Navbar_teacher } from "../components";

interface IMyProps {
  user:{
    is_teacher: boolean;
    is_superuser: boolean;
  }  
}

export const TeacherLayout = ({user}:IMyProps) => {
  if(user.is_teacher == true){    
    return (
      <>
        <Header_teacher />
        <Navbar_teacher />
        <div className="content">
          <div className="content__inner content__inner--teacher">
            <Outlet />  
          </div>
        </div>
        <Footer />
      </>  
    );  
  }
  else if(user.is_superuser){
    return <Navigate to={"/admin"} replace />;  
  }
  else{
    return <Navigate to={"/"} replace />; 
  }
};

export default TeacherLayout;