import React, {  FC, useState } from "react";
import { useAppSelector } from "src/hooks/redux";
import "../Header/Header.scss";

export const Header_admin: FC<{ isEmpty?: boolean }> = ({ isEmpty }) => {  
  if (isEmpty) {
    return (
      <div className="empty-header">
        <img className="logo" src="assets/svg/EasyEnt.svg" />
      </div>
    );
  }
  const user = useAppSelector((store) => store.user.data);

  const isUserHaveImage = (
    <div className="img">
      <img
        src="/assets/admin_ava.jpg"
      />
    </div>
  );

  const [, setShowMenu] = useState(false);

  const handleShowMenu = () => {
    setShowMenu((s) => !s);
  };


  const showUserName = `${user.full_name}`;
  return (
    <header className="header">
      <div className="container">  
        <div className="user-info">
          {isUserHaveImage}
          <div className="person-name">
            <p onClick={handleShowMenu}>
              {showUserName} <i className="fa-solid fa-angle-down"></i>
            </p>
          </div>
        </div>
      </div>
    </header>
  );
};
