import React, { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { Link } from "react-router-dom";
import { Header, Footer } from "src/components";
import { useAppSelector } from "src/hooks/redux";

export const ForgetReturnPage = () => {
  document.title = "Проверьте почту | EasyENT";

  const navigate = useNavigate();

  const isLoggedIn = useAppSelector((store) => store.user.loggedIn);

  useEffect(() => {
    if (isLoggedIn) {
      navigate("/calendar");
    }
  }, []);

  return (
    <div className="app-container">
      <Header isEmpty={true} />
      <div className="content-wrapper">
        <h1>Пароль сброщен успешно! Проверьте почту</h1>
        <br />
        <Link to="/">
            <div className=" btn btn-primary btn-flex">
                На главную
            </div>
        </Link>
      </div>
      <Footer />
    </div>
    
  );
};
export default ForgetReturnPage;