import i18next, { i18n as i18nInstance } from "i18next";
import { initReactI18next } from "react-i18next";
import { languages } from "./i18n.constants";
import { ru, kz } from "./i18n.translations";

const createI18n = (language: string): i18nInstance => {
  const i18n = i18next.createInstance().use(initReactI18next);

  i18n.init({
    lng: language,
    fallbackLng: language,
    resources: {
      [languages.ru]: ru,
      [languages.kz]: kz,
    },
  });

  return i18n;
};
let defaultLang = languages.ru;
const language = localStorage.getItem("language");
if (typeof language !== "undefined" && language !== null) {
  createI18n(languages.ru);
  if (JSON.parse(language) == "ru") {
    defaultLang = languages.ru;
  } else {
    defaultLang = languages.kz;
  }
} else {
  createI18n(languages.ru);
  localStorage.setItem("language", JSON.stringify("ru"));
}

export const i18n = createI18n(defaultLang);
