import React, { useEffect, useState } from "react";
import axios from "axios";
import { CourseListItem } from "src/components";
import { authHeader } from "src/utils/authHeader";
import "./CoursesStayStudyPage.scss";
import { ICourse } from "src/interface";

export const CoursesStayStudy = () => {
  document.title = "Курсы EasyENT | EasyENT";
  const [coursesList, setCoursesList] = useState<ICourse[]>([]);
  
  let defaultLang = "ru";
  const language = localStorage.getItem('language');
  if(typeof language !== 'undefined' && language !== null){
    if(JSON.parse(language) == "ru"){
        defaultLang = "ru";
    }
    else{
       defaultLang = "kz";
    }
  }
  const [Courselanguage, setCourselanguage] = useState(defaultLang);
  const handleChange = (event:React.ChangeEvent<HTMLSelectElement>) => {
    const value = event.target.value;
    if(typeof value == "string" && value !== null){
      setCourselanguage(value);
      localStorage.setItem('language', JSON.stringify(value));
    }
  }
  useEffect(() => {
    axios
      .get("/api/v1/courses?lang=" + defaultLang,{ headers: authHeader()})
      .then((response) => setCoursesList(response.data));
  }, [Courselanguage]);
  return (
    <>
      <h1 className="title">Курсы EasyENT по ЕНТ</h1>
      <div className="course-lang">
        <span className="course-lang__heading">Язык обучения:</span>
        <select value={defaultLang} onChange={handleChange}>
          <option value="ru">Русский</option>
          <option value="kz">Казахский</option> 
        </select>
      </div>
      
      <ul className="courses-list">
        {coursesList.map((course) => (
          <CourseListItem key={course.id} course={course} />
        ))}
      </ul>
    </>
  );
};
