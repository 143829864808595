import React from "react";
// import axios from "axios";
// import FullCalendar, { EventApi, DateSelectArg, EventClickArg, EventContentArg, formatDate } from '@fullcalendar/react';
// import ruLocale from '@fullcalendar/core/locales/ru';

import "./StaticCoins.scss";

export const StaticCoins = () => {
  document.title = "Календарь | EasyENT";

  return (
    <div>      
      <h1 className="title">Study Coins</h1>
      <div className="text">
        <p>StudyCoins - это наша внутренняя валюта. За успешное выполнение домашних заданий и контрольных работ, вы зарабатываете StudyCoins</p>
        <ul className="ul-events">
          <li>За одну домашнюю работу максимально вы можете получить <b>10 study coins</b></li>
          <li>За контрольную работу вы можете получить <b>20 study coins</b></li>
        </ul>
        <p>Study Coins начисляются в процентном соотношение, к примеру: если вы правильно ответили на 5 из 10 вопросов, вы получили 5 study coins.</p>
        <p>Если вопросов 15 и вы оветили правильно на 7 вопросов, вы получили 4,6 study coins</p>
      </div>
    </div>
  );
};
