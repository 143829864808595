import React, { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { Footer, Header, Register } from "src/components";
import { useAppSelector } from "src/hooks/redux";

import "./RegisterPage.scss";

export const RegisterPage = () => {
  document.title = "Зарегистрироваться - EasyENT";
  const navigate = useNavigate();

  const isLoggedIn = useAppSelector((store) => store.user.loggedIn);

  useEffect(() => {
    if (isLoggedIn) {
      navigate("/calendar");
    }
  }, []);
  return (
    <div className="app-container">
      <Header isEmpty={true} />
      <div className="content-wrapper">
        <Register />
      </div>
      <Footer />
    </div>
  );
};
export default RegisterPage;