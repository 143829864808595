import React from "react";
import { NavLink, Link } from "react-router-dom";

// import "./Navbar_admin.scss";

import { ReactComponent as PeopleIcon } from "../../svg/people.svg";
import { ReactComponent as TableBottomRow } from "../../svg/table-bottom-row.svg";
import { ReactComponent as Power } from "../../svg/power.svg";
import { ReactComponent as ShoppingPage } from "../../svg/shopping-bag.svg";
import { useAppDispatch } from "src/hooks/redux";
import { useNavigate } from "react-router-dom";
import { UserSlice } from "src/service/reducers/UserSlice";

export const Navbar_admin= () => {
  const { signOutUser } = UserSlice.actions;
  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  const users = {
    fullName: "",
    phone: "",
    postalCode: "",
    isStudent: true,
    personData: false,
    mailAdvertising: false,
    publicOffers: false,
  };

  const signOut = function () {
    dispatch(signOutUser(users));
    navigate('/login');
  }

  return (
    <div className="navbar">
      <Link to="/" className="logo">
        <img src="/assets/svg/EasyEnt.svg" />
      </Link>
      <nav>
        <NavLink
          className={({ isActive }) => (isActive ? "active" : undefined)}
          to="/admin/courses"
        >
          <TableBottomRow />
          Курсы
        </NavLink>
        <NavLink
          className={({ isActive }) => (isActive ? "active" : undefined)}
          to="/admin/users"
        >
          <ShoppingPage />
          Пользователи
        </NavLink>
        <NavLink
          className={({ isActive }) => (isActive ? "active" : undefined)}
          to="/admin/teachers"
        >
          <PeopleIcon />
          Преподаватели
        </NavLink>
        <NavLink
          className={({ isActive }) => (isActive ? "active" : undefined)}
          to="/admin/orders"
        >
          <PeopleIcon />
          Платежки
        </NavLink>
        <NavLink
          className={({ isActive }) => (isActive ? "active" : undefined)}
          to="/admin/tests"
        >
          <PeopleIcon />
          Тесты
        </NavLink>
        <NavLink
          className={({ isActive }) => (isActive ? "active" : undefined)}
          to="/admin/coins"
        >
          <PeopleIcon />
          Коины
        </NavLink>
        <span className="logout" onClick={signOut.bind(this)}>
          <Power />
          Выход
        </span>
      </nav>
    </div>
  );
};
