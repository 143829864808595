import axios from "axios";
import React, { FC, useEffect, useState } from "react";
import { courseTypeOption } from "src/interface";
import { authHeader } from "src/utils/authHeader";
import Select from 'react-select';
import "./AddTestQuestion.scss";

export const AddTestQuestion: FC<{ testid: string | undefined, questionlength: string | undefined }> = (params) => {
  const [questionChoise, setQuestionChoise] = useState<any>([]);
  const [questionChoiseCor, setQuestionChoiseCor] = useState<any>([]);
  const [typeQuestion, setTypeQuestion] = useState<string>('choice');
  const [groupNames, setGroupNames] = useState<any>([]);
  const typeQuestionOptions: courseTypeOption[] = [
    {value: 'choice', label: "Вопрос с вариантом"},
    {value: 'group_choice', label: "Вопрос на соответствие"},
  ];
  const [addedQuestion, setAddedQuestion] = useState<any>({
    sequence: 0,
    display_name: "string",
    question_type: typeQuestion,
    answer: "",
    variants: [
      {}
    ]
  });
  useEffect(() => {
    setAddedQuestion((s:any) => ({ ...s, 'question_type': typeQuestion }))
  }, [typeQuestion]);  
  
  const onChangeQuestion = (e: React.ChangeEvent<HTMLInputElement>) => {
    const element = e.target as HTMLInputElement;
    const value = element.value;
    const name = element.name;
    setAddedQuestion((s:any) => ({ ...s, [name]: value }));
  };
  const onChangeChoice = (e: React.ChangeEvent<HTMLInputElement>) => {
    const element = e.target as HTMLInputElement;
    const value = element.value;
    const name = element.name;
    const newChoice: any = {
      is_correct: false,
      name: name,
      value: value,
      group_name: "",
      seq_idx: element.name.substring(5)
    }; 
    
    if (questionChoise.filter((e:any) => e.name === name).length > 0) {
      const i = questionChoise.findIndex((e:any) => e.name === name);
      questionChoise[i]["value"] = value;
    } 
    else{
      questionChoise.push(newChoice);
    }
  };

  const onChangeChoiceCor = (e: React.ChangeEvent<HTMLInputElement>) => {
    const element = e.target as HTMLInputElement;
    const value = element.value;
    const name = element.name;
    const newChoice: any = {
      is_correct: false,
      name: name,
      value: value,
      group_name: "",
      seq_idx: element.name.substring(5)
    }; 
    
    if (questionChoiseCor.filter((e:any) => e.name === name).length > 0) {
      const i:any = name.substring(5);
      const choiceindex = i-1;
      questionChoiseCor[choiceindex]["value"] = value;
      console.log('cтарый');
      if(i < 5){
        questionChoiseCor[choiceindex]["group_name"] = groupNames[0];
      }
      else{
        questionChoiseCor[choiceindex]["group_name"] = groupNames[1];
      }
    } else {
      console.log('новый');
      const i:any = name.substring(5);
      console.log(i)
      if(i < 5){
        newChoice["group_name"] = groupNames[0];
      }
      else{
        newChoice["group_name"] = groupNames[1];
      }
      questionChoiseCor.push(newChoice);
    }
    setQuestionChoise(questionChoiseCor);
    setAddedQuestion((s:any) => ({
      ...s,
      ["variants"]: questionChoiseCor,
    }));  
    console.log(questionChoise);
  };
  
  const onChangeGroupName = (e: React.ChangeEvent<HTMLInputElement>) => {
    const element = e.target as HTMLInputElement;
    const changedgroupname = groupNames;
    if(element.name == "group1"){
      changedgroupname[0] = element.value;
    }
    else {
      changedgroupname[1] = element.value;
    }
    setGroupNames(changedgroupname);
    questionChoiseCor.forEach(function(part:any, index:number) {
      if(parseInt(part['seq_idx']) < 5){
        questionChoiseCor[index]['group_name'] = changedgroupname[0];
      }
      else{
        questionChoiseCor[index]['group_name'] = changedgroupname[1];
      }
      
    });
    setQuestionChoiseCor(questionChoiseCor);
    setAddedQuestion((s:any) => ({
      ...s,
      ["variants"]: questionChoiseCor,
    }));
  }
  const onChangeCorrect = (e: React.ChangeEvent<HTMLInputElement>) => {
    const element = e.target as HTMLInputElement;
    const elementid = element.id;
    if (questionChoise.filter((e:any) => e.name === elementid).length > 0) {
      const i = questionChoise.findIndex((e:any) => e.name === elementid);
      questionChoise[i]["is_correct"] = true;
    }
    setQuestionChoise(questionChoise);
    setAddedQuestion((s:any) => ({
      ...s,
      ["variants"]: questionChoise,
    }));
  };
  const onChangeCorrectCor = (e: React.ChangeEvent<HTMLInputElement>) => {
    const element = e.target as HTMLInputElement;
    const elementid = element.id;
    if (questionChoiseCor.filter((e:any) => e.name === elementid).length > 0) {
      const i = questionChoiseCor.findIndex((e:any) => e.name === elementid);
      if(questionChoiseCor[i]["is_correct"] == true){
        questionChoiseCor[i]["is_correct"] = false;
      }
      else{
        questionChoiseCor[i]["is_correct"] = true;
      }
    }
    setQuestionChoiseCor(questionChoiseCor);
    setAddedQuestion((s:any) => ({
      ...s,
      ["variants"]: questionChoiseCor,
    }));
    console.log(questionChoise);
  };

  const onSubmit = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    const newObj = addedQuestion;
    if (newObj.variants.filter((e:any) => e.is_correct === true).length > 0){
      if(params.questionlength){
        newObj["sequence"] = params.questionlength + 1;
      }
      else{
        newObj["sequence"] = 0;
      }
      axios
        .post("/api/v1/tests/question/bulk?test_id=" + params.testid, newObj, { headers: authHeader() })
        .then((response) => {
          if (response.status == 200) {
            window.location.reload();
          }
        })
        .catch((error) => {
          alert(error.response.data);
        });
    }
    else{
      alert('введите правильный вариант')
    }
  };
  return (
    <form onSubmit={onSubmit} className="add-dz box">
        <div className="form-row form-row--main">
          <label><b>Тип Вопроса:</b></label>
          <Select 
            value={typeQuestion == "choice" ? {value: 'choice', label: "Вопрос с вариантом"} : {value: 'group_choice', label: "Вопрос на соответствие"}}
            onChange={
              (option:any) => (setTypeQuestion(option.value))
            }
            options={typeQuestionOptions}
          />
        </div>
        {
          typeQuestion == "choice" ? (
            <div>
              <div className="form-row form-row--main">
                <label><b>Вопрос:</b></label>
                <input onChange={onChangeQuestion} name="display_name" type="text"></input>
              </div>
              <div className="form-row form-row--vyborka">
                <div>
                  <div className="form-choice__row">
                    <label>Значение 1</label>
                    <input
                      onChange={onChangeChoice}
                      name="value1"
                      type="text"
                    ></input>
                    <div>
                      <input
                        onChange={onChangeCorrect}
                        id="value1"
                        type="checkbox"
                        name="correct"
                      ></input>
                    </div>
                  </div>
                  <div className="form-choice__row">
                    <label>Значение 2</label>
                    <input
                      name="value2"
                      onChange={onChangeChoice}
                      type="text"
                    ></input>
                    <div>
                      <input
                      onChange={onChangeCorrect}
                        type="checkbox"
                        id="value2"
                        name="correct"
                      ></input>
                    </div>
                  </div>
                  <div className="form-choice__row">
                    <label>Значение 3</label>
                    <input
                      name="value3"
                      onChange={onChangeChoice}
                      type="text"
                    ></input>
                    <div>
                      <input
                        onChange={onChangeCorrect}
                        type="checkbox"
                        id="value3"
                        name="correct"
                      ></input>
                    </div>
                  </div>
                  <div className="form-choice__row">
                    <label>Значение 4</label>
                    <input
                      name="value4"
                      onChange={onChangeChoice}
                      type="text"
                    ></input>
                    <div>
                      <input
                        onChange={onChangeCorrect}
                        type="checkbox"
                        id="value4"
                        name="correct"
                      ></input>
                    </div>
                  </div>
                  <div className="form-choice__row">
                    <label>Значение 5</label>
                    <input
                      name="value5"
                      onChange={onChangeChoice}
                      type="text"
                    ></input>
                    <div>
                      <input
                        onChange={onChangeCorrect}
                        type="checkbox"
                        id="value5"
                        name="correct"
                      ></input>
                    </div>
                  </div>
                  <div className="form-choice__row">
                    <label>Значение 6</label>
                    <input
                      name="value6"
                      onChange={onChangeChoice}
                      type="text"
                    ></input>
                    <div>
                      <input
                        onChange={onChangeCorrect}
                        type="checkbox"
                        id="value6"
                        name="correct"
                      ></input>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          ) : 
          (
            <div>
              <div className="form-row form-row--main">
                <label><b>Вопрос:</b></label>
                <input onChange={onChangeQuestion} name="display_name" type="text"></input>
              </div>
              <table className="group-table group-table--big">
                <tbody>
                <tr>
                  <td>
                    <input required onChange={onChangeGroupName} name="group1" type="text"/>
                  </td>
                  <td>
                    <ul className="group-ul">
                      <li>
                        <div className="admin-choice">
                          <input
                            className="admin-choice__checkbox"
                            onChange={onChangeCorrectCor}
                            type="checkbox"
                            id="value1"
                            name="correct">
                          </input>
                          <div className="admin-choise__right">
                            <input required onChange={onChangeChoiceCor} name="value1" type="text"/>
                          </div>
                        </div>
                      </li>
                      <li>
                        <div className="admin-choice">
                          <input
                            className="admin-choice__checkbox"
                            onChange={onChangeCorrectCor}
                            type="checkbox"
                            id="value2"
                            name="correct">
                          </input>
                          <div className="admin-choise__right">
                            <input required onChange={onChangeChoiceCor} name="value2" type="text"/>
                          </div>
                        </div>
                      </li>
                      <li>
                        <div className="admin-choice">
                          <input
                            className="admin-choice__checkbox"
                            onChange={onChangeCorrectCor}
                            type="checkbox"
                            id="value3"
                            name="correct">
                          </input>
                          <div className="admin-choise__right">
                            <input required onChange={onChangeChoiceCor} name="value3" type="text"/>
                          </div>
                        </div>
                      </li>
                      <li>
                        <div className="admin-choice">
                          <input
                            className="admin-choice__checkbox"
                            onChange={onChangeCorrectCor}
                            type="checkbox"
                            id="value4"
                            name="correct">
                          </input>
                          <div className="admin-choise__right">
                            <input required onChange={onChangeChoiceCor} name="value4" type="text"/>
                          </div>
                        </div>
                      </li>
                    </ul>
                  </td>
                </tr>
                <tr>
                  <td>
                    <input required onChange={onChangeGroupName} name="group2" type="text"/>
                  </td>
                  <td>
                    <ul className="group-ul">
                      <li>
                        <div className="admin-choice">
                          <input
                            className="admin-choice__checkbox"
                            onChange={onChangeCorrectCor}
                            type="checkbox"
                            id="value5"
                            name="correct">
                          </input>
                          <div className="admin-choise__right">
                            <input required 
                              onChange={onChangeChoiceCor} name="value5" type="text"
                            />
                          </div>
                        </div>
                      </li>
                      <li>
                        <div className="admin-choice">
                          <input
                            className="admin-choice__checkbox"
                            onChange={onChangeCorrectCor}
                            type="checkbox"
                            id="value6"
                            name="correct">
                          </input>
                          <div className="admin-choise__right">
                            <input required onChange={onChangeChoiceCor} name="value6" type="text"/>
                          </div>
                        </div>
                      </li>
                      <li>
                        <div className="admin-choice">
                          <input
                            className="admin-choice__checkbox"
                            onChange={onChangeCorrectCor}
                            type="checkbox"
                            id="value7"
                            name="correct">
                          </input>
                          <div className="admin-choise__right">
                            <input required onChange={onChangeChoiceCor}  name="value7" type="text"/>
                          </div>
                        </div>
                      </li>
                      <li>
                        <div className="admin-choice">
                          <input
                            className="admin-choice__checkbox"
                            onChange={onChangeCorrectCor}
                            type="checkbox"
                            id="value8"
                            name="correct">
                          </input>
                          <div className="admin-choise__right">
                            <input required onChange={onChangeChoiceCor}  name="value8" type="text"/>
                          </div>
                        </div>
                      </li>
                    </ul>
                  </td>
                </tr>
                </tbody>      
              </table>
            </div>
          )
        }
        <button className="btn btn-primary" type="submit">
          Добавить
        </button>
      </form>
  );
}
