import axios from "axios";
import React, { useEffect, useState } from "react";
import { authHeader } from "src/utils/authHeader";
import "./BestStudent.scss";

export const BestStudent = () => {
  document.title = "Лучшие Ученики | EasyENT";
  const [bestusers, setBestUsers] = useState<any>([]);
  let number = 0;
  useEffect(() => {
  axios
    .get("/api/v1/users/leader?limit=20",{ headers: authHeader()})
    .then((response) => setBestUsers(response.data));
  }, []);
  return (
    <div className="shopping-page">
      <h1 className="title">ТОП-20 Лучших учеников</h1>
      <table className="table">
        <tbody>
          <tr>
            <th style={{width: '40px'}}>
              #
            </th>
            <th style={{width: '220px'}}>
              Фио
            </th>          
            <th style={{width: '180px'}}>
              Коины
            </th>
          </tr>
          {
            bestusers.map((user:any) => (
            <tr key={user.id}>
              <td>
                {number += 1}
              </td>
              <td>
                {user.full_name}
              </td>          
              <td>
                {user.coins.toFixed(0)}
              </td>
            </tr>
          ))}
        </tbody>
      </table>  
    </div>
  );
};
