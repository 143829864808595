import axios from "axios";
import React, { FC, useEffect, useState } from "react";
import { AdminUser, ICourse, User_feeds } from "src/interface";
import { authHeader } from "src/utils/authHeader";
import "./CourseListAdmin.scss";
export const CourseListAdmin: FC<{
  courseLang: string;
  coursesList: ICourse[];
  dateStart: string;
  dateEnd: string;
  user: AdminUser;
}> = ({ dateStart, dateEnd, user, coursesList }) => {
  const [userFeeds, setUserFeeds] = useState<User_feeds[]>([]);
  const [sendUserFeed, setSendUserFeed] = useState<boolean>(true);
  useEffect(() => {
    if (user && user.user_feeds) {
      setUserFeeds(user.user_feeds);
    }
  }, []);
  const addUserFeed = (e: React.MouseEvent<Element>) => {
    let start_date = "",
      end_date = "";
    const element = e.currentTarget;
    if (element.getAttribute("data-potok") == "10") {
      start_date = dateStart + "-9";
      end_date = dateEnd + "-9";
    } else if (element.getAttribute("data-potok") == "5") {
      start_date = dateStart + "-13";
      end_date = dateEnd + "-10";
    }
    if (start_date && end_date) {
      const newobj = {
        user_id: parseInt("" + element.getAttribute("data-user-id")),
        course_id: parseInt("" + element.getAttribute("data-course-id")),
        start_date: start_date,
        end_date: end_date,
        status: "active",
      };
      axios
        .post("/api/v1/user_feed/", newobj, { headers: authHeader() })
        .then((response) => {
          if (response.status == 200) {
            console.log(response);
            setUserFeeds((prevState) => [...prevState, response.data]);
            setSendUserFeed(true);
          }
        })
        .catch((error) => {
          alert(error.response.data);
        });
    }
  };

  const removeUserFeed = (e: React.MouseEvent<Element>) => {
    setSendUserFeed(false);
    const element = e.currentTarget;
    if (element.getAttribute("data-course-id") !== null) {
      let start_date = "";
      if (element.getAttribute("data-potok") == "10") {
        start_date = dateStart + "-9";
      } else if (element.getAttribute("data-potok") == "4") {
        start_date = dateStart + "-18";
      }
      const obj: any = userFeeds.find(
        (o) =>
          o.start_date === start_date &&
          String(o.course_id) === element.getAttribute("data-course-id")
      );
      obj.status = "inactive";
      console.log(obj);
      if (obj) {
        axios
          .put("/api/v1/user_feed/" + obj.id + "/", obj, {
            headers: authHeader(),
          })
          .then((response) => {
            if (response.status == 200) {
              setUserFeeds((prevState) => [...prevState, obj]);
              setSendUserFeed(true);
            }
          })
          .catch((error) => {
            alert(error.response.data);
          });
      }
    }
  };
  const updateUserFeed = (e: React.MouseEvent<Element>) => {
    setSendUserFeed(false);
    const element = e.currentTarget;
    if (element.getAttribute("data-course-id") !== null) {
      let start_date = "";
      if (element.getAttribute("data-potok") == "10") {
        start_date = dateStart + "-9";
      } else if (element.getAttribute("data-potok") == "2") {
        start_date = dateStart + "-13";
      } else if (element.getAttribute("data-potok") == "3") {
        start_date = dateStart + "-15";
      } else if (element.getAttribute("data-potok") == "4") {
        start_date = dateStart + "-18";
      }
      const obj: any = userFeeds.find(
        (o) =>
          o.start_date === start_date &&
          String(o.course_id) === element.getAttribute("data-course-id")
      );
      obj.status = "active";
      axios
        .put("/api/v1/user_feed/" + obj.id + "/", obj, {
          headers: authHeader(),
        })
        .then((response) => {
          if (response.status == 200) {
            setUserFeeds((prevState) => [...prevState, obj]);
            setSendUserFeed(true);
          }
        })
        .catch((error) => {
          alert(error.response.data);
        });
    }
  };

  return (
    <>
      <div>
        <div className="addcourse-ul">
          {coursesList.map((course) => (
            <div key={course.id + "_10"}>
              {course.active ? (
                <div className="addcourse-list">
                  {userFeeds.filter(
                    (e) =>
                      e.course_id === course.id &&
                      e.start_date.slice(0, -3) == dateStart
                  ).length > 0 ? (
                    userFeeds.filter(
                      (e) =>
                        e.course_id === course.id &&
                        e.start_date.slice(0, -3) == dateStart &&
                        e.status == "active"
                    ).length > 0 ? (
                      <div
                        onClick={
                          sendUserFeed == true
                            ? (event) => removeUserFeed(event)
                            : () => console.log("user feed отменяется")
                        }
                        data-start={dateStart}
                        data-course-id={course.id}
                        data-potok={course.price}
                        className="tipo-input"
                      >
                        <i className="fa-solid fa-check"></i>
                      </div>
                    ) : (
                      <div
                        onClick={
                          sendUserFeed == true
                            ? (event) => updateUserFeed(event)
                            : () => console.log("user feed отменяется")
                        }
                        data-start={dateStart}
                        data-course-id={course.id}
                        data-potok={course.price}
                        className="tipo-input"
                      ></div>
                    )
                  ) : (
                    <>
                      {sendUserFeed == true ? (
                        <div
                          onClick={(event) => (
                            setSendUserFeed(false), addUserFeed(event)
                          )}
                          data-user-id={user ? user.id : ""}
                          data-start={dateStart}
                          data-end={dateEnd}
                          data-potok={course.price}
                          data-course-id={course.id}
                          className="tipo-input"
                        ></div>
                      ) : (
                        <div className="tipo-input"></div>
                      )}
                    </>
                  )}
                  <p>{course.name}</p>
                </div>
              ) : (
                ""
              )}
            </div>
          ))}
        </div>
      </div>
    </>
  );
};
