export const namespaces = {
    pages: {
      hello: "pages.hello",
    },
    common: "common",
  };
  
  export const languages = {
    ru: "ru",
    kz: "kz",
  };